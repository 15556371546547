import { InlineField, InlineSwitch } from '@grafana/ui';
import React from 'react';

interface EnableAppO11ySwitchProps {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

export const EnableAppO11ySwitch = ({ enabled, setEnabled }: EnableAppO11ySwitchProps) => {
  return (
    <div>
      <InlineField
        tooltip={'If enabled, generates host metrics based on telemetry data. Required by Application Observability.'}
        label={'Enable Application Observability'}
      >
        <InlineSwitch onChange={(e) => setEnabled(e.currentTarget.checked)} value={enabled} />
      </InlineField>
    </div>
  );
};
