import { QueryIngest } from '@/components/QueryIngestRatio/types';

type CommonPatternModel = {
  configured_drop_rate: number;
  locked: boolean;
  pattern: string;
};

/** This is what the /recommendations PATCH API receives */
export type PatternUpdateModel = Pick<CommonPatternModel, 'pattern'> &
  Partial<CommonPatternModel> & {
    segments?: Record<string, SegmentUpdateModel>;
  };

type SegmentUpdateModel = {
  configured_drop_rate?: number;
};

/** This is what is received from the /recommendations GET API (a list of these)*/
export type RecommendationResponseItem = CommonPatternModel & {
  attribution: Record<string, Attribution>;
  ingested_lines: number;
  queried_lines: number;
  recommended_drop_rate: number;
  volume: number;
};

/** This is what is used when displaying the Patterns table */
export type PatternRecommendation = {
  // Add a precalculated query ingest summary
  queryIngest: QueryIngest;
} & // Add the entire response item, omitting the fields used for calculation
Omit<RecommendationResponseItem, 'ingested_lines' | 'queried_lines'> &
  // Add a precalculated segments map
  PatternUpdateModel;

type Attribution = {
  configured_drop_rate?: number;
  Count: number;
  Volume: number;
};

export function getPatternRecommendationKey(recommendation: PatternRecommendation) {
  return recommendation.pattern;
}

export type Summary = {
  dropped_volume: number;
  kept_volume: number;
  total_ingested_volume: number;
  unqueried_volume: number;
};

export type Metrics = {
  dropped_volume: number;
  ingested_volume: number;
};

export type ListResponseWithHeaders<T> = {
  items: T[];
  // FIXME: Where are the headers???
};

export type MappedListResponse<Key, T> = ListResponseWithHeaders<T> & {
  duplicateItems: T[];
  /**
   * Based on the selected keys, return the items
   * @param selectedKeys the set of keys we have selected (return all if empty)
   * @returns
   */
  getSelectedItems: (selected: Set<Key>) => T[];
  mappedItems: Map<Key, T>;
};

export type Exemption = {
  created_at: string;
  created_by: string;
  id: string;
  managed_by?: string;
  reason?: string;
  stream_selector: string;
  updated_at: string;
};

export type ExemptionsQueryResponse = {
  result: Exemption[];
};
