import { config } from '@grafana/runtime';
import { AgentOptions } from './enums';
import { DurationUnit } from '@grafana/data';
import { WindowBreakpoints } from 'types';
import { RelativeRange } from 'store/timeRange';

export const PLUGIN_ID = 'grafana-k8s-app';
export const PLUGIN_ROOT_URL = `/a/${PLUGIN_ID}`;

export const PAGINATION_PAGE_SIZES = [
  {
    label: '15',
    value: 15,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const DEFAULT_INITIAL_PAGE = 1;
export const DEFAULT_TABLE_ID = 'default';

export const WORKLOAD_TYPE_MAPPING = {
  ReplicaSet: 'deployment',
  Job: 'job',
  DaemonSet: 'daemonset',
  StatefulSet: 'statefulset',
};

export const INTEGRATIONS_API_ERROR = {
  403: 'Permission denied trying to access integration API. Make sure you have the correct permission to view this page.',
};

export const K8S_INTEGRATION_ID = 'kubernetes';

export const colors = {
  blue03: '#538ade',
  blue04: '#343b40',
  blue07: '#245baf',
};

export const K8S_STORAGE_KEY = 'grafana.k8s-app.navigation.storage';

export const EFFICIENCY_UNDER_UTILIZED_THRESHOLD = 0.6;
export const EFFICIENCY_OVER_UTILIZED_THRESHOLD = 0.9;
export const EFFICIENCY_MAX_UTILIZED_THRESHOLD = 0.9;

export const EFFICIENCY_USAGE_METRICS = {
  cluster: {
    cpu: ['container_cpu_usage_seconds_total', 'kube_node_status_capacity'],
    memory: ['container_memory_working_set_bytes', 'machine_memory_bytes'],
    storage: ['node_filesystem_size_bytes', 'node_filesystem_avail_bytes'],
  },
  namespace: {
    cpu: ['container_cpu_usage_seconds_total', 'node_cpu_seconds_total'],
    memory: ['container_memory_working_set_bytes', 'machine_memory_bytes'],
    storage: ['kubelet_volume_stats_available_bytes', 'kubelet_volume_stats_capacity_bytes'],
  },
  node: {
    cpu: ['node_cpu_seconds_total'],
    memory: ['container_memory_working_set_bytes', 'machine_memory_bytes'],
    storage: ['node_filesystem_size_bytes', 'node_filesystem_avail_bytes'],
  },
};

export const MAX_ITEMS_PER_LIST = 12;
export const MAX_NUMBER_OF_LISTS = 2;

// Faro settings
const FARO_DEV_ENDPOINT =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/f0e899cd74e7e893677f290f38a11a65';
const FARO_OPS_ENDPOINT =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/ebafdd81fcb463242ed5ed9bcc8ea802';
const FARO_PROD_ENDPOINT =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/e3f6441bd29dc97e68acade53dd3ecdb';
const appUrl = config.appUrl;
const FARO_APP_DEV = 'grafana-k8s-app-dev';
const FARO_APP_PROD = 'grafana-k8s-app-prod';
const FARO_APP_OPS = 'grafana-k8s-app-ops';

// This isn't great but the other options using config object aren't great either
export const FARO_ENDPOINT =
  appUrl.includes('grafana-dev.net') || appUrl.includes('localhost')
    ? FARO_DEV_ENDPOINT
    : appUrl.includes('grafana-ops.net')
    ? FARO_OPS_ENDPOINT
    : FARO_PROD_ENDPOINT;

export const FARO_APP_NAME =
  appUrl.includes('grafana-dev.net') || appUrl.includes('localhost')
    ? FARO_APP_DEV
    : appUrl.includes('grafana-ops.net')
    ? FARO_APP_OPS
    : FARO_APP_PROD;

export const NO_DATA_FLOWING_MAP = {
  nodes: {
    title: 'No nodes detected',
    description:
      'Connect a data source to view your nodes. Then, you can filter by cluster or condition, view node resource utilization, and predict CPU or memory usage.',
  },
  clusters: {
    title: 'No clusters detected',
    description:
      'Connect a data source to view your clusters. Then, you can filter by cluster, provider or efficiency, and drill into cluster details.',
  },
  namespaces: {
    title: 'No namespaces detected',
    description:
      'Connect a data source to view your namespaces. Then, you can filter by cluster or namespace, see alerts, and drill into namespace details.',
  },
  workloads: {
    title: 'No workloads detected',
    description:
      'Connect a data source to view your workloads. Then, you can filter by workload type, see alerts, available pods and drill into workload details.',
  },
  efficiency: {
    title: 'No data detected',
    description:
      'Connect a data source to explore efficiency view. It provides a correlation between CPU, memory, and storage use for clusters, nodes, and namespaces.',
  },
  costs: {
    title: 'No data detected',
    description:
      'Connect a data source to explore cost view. It enables you to manage the cost of cloud resources that your Kubernetes infrastructure is consuming.',
  },
  search: {
    title: 'No data detected',
    description: 'Connect a data source to search your k8s infrastructure.',
  },
};

export const agentOptions = [
  { label: 'Helm Chart', value: AgentOptions.Alloy },
  { label: 'Agent', value: AgentOptions.AgentInStaticMode },
  { label: 'Agent Operator', value: AgentOptions.AgentOperator },
];

// Update to set a different initial time range on scenes timerange picker
export const DEFAULT_TIME_AMOUNT = 1;
export const DEFAULT_TIME_UNIT: DurationUnit = 'h';
export const DEFAULT_INITIAL_TIME_RANGE: RelativeRange = {
  from: `now-${DEFAULT_TIME_AMOUNT}${DEFAULT_TIME_UNIT}`,
  to: 'now',
};

export const DEFAULT_REFRESH_INTERVAL = '1m';
export const REFRESH_INTERVAL_OPTIONS = [DEFAULT_REFRESH_INTERVAL, '5m', '15m', '1h'];

export const windowBreakpoints: WindowBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1440,
};

export const URL_ENCODE_FORWARD_SLASH = '---';

export const MAX_WORKLOADS_WITH_DETAILS = 1000;
export const MAX_SELECTABLE_ASSERTS_COMPARE_ROWS = 20;

// Regex to detect grafana cloud hosted datasources
export const RE_GRAFANA_CLOUD_HOST = /grafana(-dev|-ops)?\.net/;
