import { PatternRecommendation } from '@/api/types';

export function getVolumeAffectableByPatternDropRate(recommendations: PatternRecommendation[]) {
  /** Excludes volume with per-segment drop rate */
  let totalAffectableByPatternDropRate = 0;
  let totalDroppedBySegmentRates = 0;

  recommendations.forEach((r) => {
    for (const { configured_drop_rate, Volume: volume } of Object.values(r.attribution)) {
      if (configured_drop_rate === undefined) {
        totalAffectableByPatternDropRate += volume;
      } else {
        totalDroppedBySegmentRates += (volume * configured_drop_rate) / 100;
      }
    }
  });

  return { totalAffectableByPatternDropRate, totalDroppedBySegmentRates };
}
