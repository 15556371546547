import { every, sum } from 'lodash';

import { PatternRecommendation, PatternUpdateModel } from '@/api/types';

function getPatternsWithSegment(patterns: PatternRecommendation[], segment: string) {
  return patterns.filter((item) => item.attribution[segment] != null);
}

export function getSegmentEditorInfo(selected: PatternRecommendation[], segment: string) {
  const patterns = getPatternsWithSegment(selected, segment);

  let totalVolume = 0;
  let droppedVolume = 0;
  const alreadySet: PatternRecommendation[] = [];
  const alreadySetRates: number[] = [];

  for (const pattern of patterns) {
    const { configured_drop_rate: drop_rate_override, Volume: volume } = pattern.attribution[segment];
    let rate: number;

    if (drop_rate_override != null) {
      alreadySetRates.push(drop_rate_override);
      rate = drop_rate_override / 100;
      alreadySet.push(pattern);
    } else {
      rate = pattern.configured_drop_rate / 100;
    }

    totalVolume += volume;
    droppedVolume += volume * rate;
  }

  // Note: These will not be accessed if `alreadySet` is empty
  const averageSegmentDropRate = sum(alreadySetRates) / alreadySetRates.length;
  const allSegmentDropRatesEqual = every(alreadySetRates.map((dropRate) => dropRate === averageSegmentDropRate));

  return {
    allSegmentDropRatesEqual,
    averageSegmentDropRate,
    droppedVolume,
    dropRate: '',
    patterns,
    patternsWithSegmentDropRate: alreadySet,
    segment,
    totalVolume,
  };
}

export type SegmentVolumeSummary = {
  droppedVolume: number;
  segment: string;
  totalVolume: number;
};

export function calculateChangeInDroppedVolume(
  segments: SegmentVolumeSummary[],
  updates: PatternUpdateModel['segments']
) {
  if (!updates) {
    // Programmer error
    throw new Error('The updates record cannot be undefined');
  }

  let deltaVolume = 0;
  for (const segment of segments) {
    const update = updates[segment.segment];
    if (update?.configured_drop_rate === undefined) {
      // This segment was not modified -- NO EFFECT!
      continue;
    }
    // Remove the volume that was dropped by the previous drop rate(s) for this segment
    deltaVolume -= segment.droppedVolume;

    // Add the updated dropped volume for this segment
    deltaVolume += (segment.totalVolume * update.configured_drop_rate) / 100;
  }
  return deltaVolume;
}

type SegmentVolumeSummaryWithPattens = SegmentVolumeSummary & {
  patternsWithSegmentDropRate: PatternRecommendation[];
};

export function calculateChangeInDroppedVolumeForClearingPerServiceDropRates(
  segments: SegmentVolumeSummaryWithPattens[]
) {
  let deltaVolume = 0;
  for (const segment of segments) {
    for (const overriddenPattern of segment.patternsWithSegmentDropRate) {
      const segmentData = overriddenPattern.attribution[segment.segment];
      if (!segmentData) {
        // If for some reason we have a segment override for a segment that doesn't exist in `attribution`,
        // We will just ignore it. This is NOT expected to occur.
        continue;
      }
      const { configured_drop_rate, Volume: volume } = segmentData;
      // Remove the volume that was dropped by the previously configured drop rate override for this segment
      deltaVolume -= (volume * configured_drop_rate!) / 100;
      // Add the updated dropped volume based on the base pattern drop rate
      deltaVolume += (volume * overriddenPattern.configured_drop_rate) / 100;
    }
  }
  return deltaVolume;
}
