import { SelectableValue } from '@grafana/data';

import { QueryIngestRange } from './types';

const ALWAYS: QueryIngestRange = {
  description: '100%',
  isAbove: (_percent) => false,
  label: 'Always',
};
const queryIngestRanges: QueryIngestRange[] = [
  {
    description: '0%',
    isAbove: (ratio) => ratio > 0,
    label: 'Never',
  },
  {
    description: 'less than 1%',
    isAbove: (ratio) => ratio > 0.01,
    label: 'Rarely',
  },
  {
    description: 'between 1% and 40%',
    isAbove: (ratio) => ratio > 0.4,
    label: 'Sometimes',
  },
  {
    description: 'between 40% and 100%',
    isAbove: (ratio) => ratio >= 1,
    label: 'Often',
  },
  ALWAYS,
] as const;

export function getQueryIngestRange(ratio: number) {
  for (const range of queryIngestRanges) {
    if (!range.isAbove(ratio)) {
      return range;
    }
  }
  return ALWAYS;
}

export const queryIngestFilterOptions: Array<SelectableValue<QueryIngestRange>> = [
  ...queryIngestRanges.map((t) => ({ ...t, value: t })),
] as const;
