import { getQueryIngestRange } from './ranges';

export type QueryIngestRange = {
  description: string;
  isAbove: (ratio: number) => boolean;
  label: string;
};

export type QueryIngest = {
  range: QueryIngestRange;
  ratio: number;
};

export function getQueryIngest(queriedLines: number, ingestedLines: number) {
  const ratio = queriedLines / ingestedLines;
  const range = getQueryIngestRange(ratio);
  return { range, ratio };
}
