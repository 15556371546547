import { DEFAULT_PYROSCOPE_URL, GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER } from 'utils/consts';
import { LanguageOptions } from 'utils/enums';

const GrafanaAgentConfig = `
pyroscope.write "example" {
  endpoint {
    url = "${DEFAULT_PYROSCOPE_URL}"
    basic_auth {
      username = "${GRAFANA_EXAMPLE_USER}"
      password = "${GRAFANA_EXAMPLE_API}"
    }
  }
}
`;

const GolangConfig = `
package main

import "github.com/grafana/pyroscope-go"

func main() {
  pyroscope.Start(pyroscope.Config{
    ApplicationName:   "simple.golang.app",
    ServerAddress:     "${DEFAULT_PYROSCOPE_URL}",
    BasicAuthUser:     "${GRAFANA_EXAMPLE_USER}",
    BasicAuthPassword: "${GRAFANA_EXAMPLE_API}",
  })

  // your code goes here
}
`;

const JavaConfig = `
import io.pyroscope.javaagent.PyroscopeAgent;
import io.pyroscope.javaagent.config.Config;

PyroscopeAgent.start(
  new Config.Builder()
    .setApplicationName("example.java.app")
    .setProfilingEvent(EventType.ITIMER)
    .setFormat(Format.JFR)
    .setServerAddress("${DEFAULT_PYROSCOPE_URL}")
    .setBasicAuthUser("${GRAFANA_EXAMPLE_USER}")
    .setBasicAuthPassword("${GRAFANA_EXAMPLE_API}")
    .build()
);
`;

const DotnetConfig = `
PYROSCOPE_PROFILING_ENABLED=1
CORECLR_ENABLE_PROFILING=1
CORECLR_PROFILER={BD1A650D-AC5D-4896-B64F-D6FA25D6B26A}
CORECLR_PROFILER_PATH=Pyroscope.Profiler.Native.so
LD_PRELOAD=Pyroscope.Linux.ApiWrapper.x64.so

PYROSCOPE_APPLICATION_NAME=example.dotnet.app
PYROSCOPE_SERVER_ADDRESS=${DEFAULT_PYROSCOPE_URL}
PYROSCOPE_BASIC_AUTH_USER=${GRAFANA_EXAMPLE_USER}
PYROSCOPE_BASIC_AUTH_PASSWORD=${GRAFANA_EXAMPLE_API}
`;

const EBPFConfig = `
pyroscope.write "example" {
  endpoint {
    url = "${DEFAULT_PYROSCOPE_URL}"
    basic_auth {
      username = "${GRAFANA_EXAMPLE_USER}"
      password = "${GRAFANA_EXAMPLE_API}"
    }
  }
}
`;

const PythonConfig = `
import pyroscope

pyroscope.configure(
  application_name    = "example.python.app",
  server_address      = "${DEFAULT_PYROSCOPE_URL}",
  basic_auth_username = "${GRAFANA_EXAMPLE_USER}",
  basic_auth_password = "${GRAFANA_EXAMPLE_API}",
)
`;

const RubyConfig = `
require "pyroscope"

Pyroscope.configure do |config|
  config.application_name    = "example.ruby.app"
  config.server_address      = "${DEFAULT_PYROSCOPE_URL}"
  config.basic_auth_username = "${GRAFANA_EXAMPLE_USER}"
  config.basic_auth_password = "${GRAFANA_EXAMPLE_API}"
end
`;

const NodeJsConfig = `
const Pyroscope = require('@pyroscope/nodejs');

Pyroscope.init({
  appName:           'example.node.app',
  serverAddress:     '${DEFAULT_PYROSCOPE_URL}',
  basicAuthUser:     '${GRAFANA_EXAMPLE_USER}',
  basicAuthPassword: '${GRAFANA_EXAMPLE_API}',
});

Pyroscope.start()
`;

const RustConfig = `
let pprof_config = PprofConfig::new().sample_rate(100);
let pprof_backend = Pprof::new(pprof_config);

let agent =
  PyroscopeAgent::builder("${DEFAULT_PYROSCOPE_URL}", "example.rust.app")
  .basic_auth_username("${GRAFANA_EXAMPLE_USER}")
  .basic_auth_password("${GRAFANA_EXAMPLE_API}")
  // Profiling backend
  .backend(pprof_backend)
  // Create the agent
  .build()?;
`;

const configs = {
  [LanguageOptions.GrafanaAlloy]: GrafanaAgentConfig,
  [LanguageOptions.GrafanaAgent]: GrafanaAgentConfig,
  [LanguageOptions.Golang]: GolangConfig,
  [LanguageOptions.Java]: JavaConfig,
  [LanguageOptions.Dotnet]: DotnetConfig,
  [LanguageOptions.EBPF]: EBPFConfig,
  [LanguageOptions.Python]: PythonConfig,
  [LanguageOptions.Ruby]: RubyConfig,
  [LanguageOptions.NodeJs]: NodeJsConfig,
  [LanguageOptions.Rust]: RustConfig,
};

const docLinks = {
  [LanguageOptions.GrafanaAlloy]: 'https://grafana.com/docs/pyroscope/latest/configure-client/grafana-agent/',
  [LanguageOptions.GrafanaAgent]: 'https://grafana.com/docs/pyroscope/latest/configure-client/grafana-agent/',
  [LanguageOptions.Golang]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/go_push/',
  [LanguageOptions.Java]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/java/',
  [LanguageOptions.Dotnet]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/dotnet/',
  [LanguageOptions.EBPF]: 'https://grafana.com/docs/pyroscope/latest/configure-client/grafana-alloy/ebpf/',
  [LanguageOptions.Python]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/python/',
  [LanguageOptions.Ruby]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/ruby/',
  [LanguageOptions.NodeJs]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/nodejs/',
  [LanguageOptions.Rust]: 'https://grafana.com/docs/pyroscope/latest/configure-client/language-sdks/rust/',
};

export { configs, docLinks };
