import { Labels, RawTimeRange, TimeRange } from '@grafana/data';
import { DataQuery, DataSourceRef } from '@grafana/schema';

import { OnCallAlertGroup } from './oncall';

export * from './oncall';

export type HyperparameterValues = ProphetFormValues;

export interface ModelHyperparameters {
  // eslint-disable-next-line camelcase
  grafana_prophet_1_0_1?: ProphetFormValues;

  // eslint-disable-next-line camelcase
  grafana_augurs_mstl_0_1_0?: undefined;

  // eslint-disable-next-line camelcase
  philwinder_averaging_model_0_0_1?: undefined;
}
export type ModelId = keyof ModelHyperparameters;

export interface ModelForm {
  id: ModelId;
  intervalValue: number | string;
  intervalUnit: TimeUnit;
  trainingWindowValue: number | string;
  trainingWindowUnit: TimeUnit;
  trainingFrequencyValue: number | string;
  trainingFrequencyUnit: TimeUnit;
  hyperparameters: ModelHyperparameters;
}

export interface ProphetFormValues {
  // eslint-disable-next-line camelcase
  changepoint_prior_scale: number | string;

  // eslint-disable-next-line camelcase
  changepoint_range: number | undefined;

  // eslint-disable-next-line camelcase
  seasonality_prior_scale: number | string;

  // eslint-disable-next-line camelcase
  holidays_prior_scale: number | string;

  // eslint-disable-next-line camelcase
  seasonality_mode: 'additive' | 'multiplicative';

  // eslint-disable-next-line camelcase
  growth: 'flat' | 'linear' | 'logistic';

  // eslint-disable-next-line camelcase
  interval_width: number | string;

  // eslint-disable-next-line camelcase
  weekly_seasonality: number | string | undefined;

  // eslint-disable-next-line camelcase
  conditional_weekly_seasonality: string | undefined;

  // eslint-disable-next-line camelcase
  daily_seasonality: number | string | undefined;

  // eslint-disable-next-line camelcase
  conditional_daily_seasonality: string | undefined;

  // eslint-disable-next-line camelcase
  logistic_growth_cap: number | undefined;

  // eslint-disable-next-line camelcase
  logistic_growth_floor: number | undefined;

  transformation: Transformation | undefined;
}

export interface Transformation {
  id: string;
  parameters?: Record<string, any>;
}

export interface SerializableTimeRange {
  from: string;
  to: string;
}

// @ts-expect-error - this is a legacy import and doesn't come with types.
declare module 'grafana/app/core/app_events';

export interface Metadata {
  id: string;
  name: string;
  created?: string;
  modified?: string;
  createdBy?: string;
  modifiedBy?: string;
  tenantId?: string;
}

export type UnnamedMetadata = Omit<Metadata, 'name'>;

export interface TrainingHistoryItem {
  algorithm: string;
  created: string;
  datasourceId?: number;
  datasourceUid?: string;
  datasourceType: string;
  finished: string;
  hyperParams?: HyperparameterValues;
  error?: string;
  interval: number;
  queryParams: { exemplar: boolean; expr: string; legendFormat: string; refId: string };
  series: Array<{ tags: { instance: string; job: string } }>;
  started: string;
  trainingWindow: number;
}

export type JobStatus =
  | 'active'
  | 'error'
  | 'inactive'
  | 'initial training'
  | 'pending'
  | 'ready'
  | 'training'
  | 'unknown';

export type ErrorKind = 'system' | 'timeout' | 'user';

export type Job = Metadata & NewMetricJob;

/// Represent a model from the backend
export interface Model {
  id: string;
  version: string;
  name: string;
  description: string;
  website?: URL;
  hyperparameters?: unknown;
  deprecated: boolean;
}

/// Represent a mutation or creation of a metric job
export interface NewMetricJob {
  name: string;
  metric: string;
  status?: JobStatus;
  description?: string;
  grafanaUrl?: string;
  grafanaApiKey?: string;
  datasourceId?: number;
  datasourceUid?: string;
  datasourceType?: string;
  queryParams?: DataQuery;
  interval?: number;
  algorithm?: string;
  hyperParams?: HyperparameterValues;
  trainingWindow?: number;
  lastTrainingStatus?: { status: string; error?: string; warnings?: string[]; errorKind?: ErrorKind };
  trainingFrequency?: number;
  nextTrainingAt?: string;
  trainingScheduledAt?: string;
  trainingCompletedAt?: string;
  trainingResult?: string;
  disabled?: boolean;
  disabledReason?: string;
  holidays?: string[];
  customLabels?: { [key: string]: string };
}

export interface ValidateNewJob {
  name?: string;
  metric?: string;
}

export interface ValidateNewJobResult {
  valid: boolean;
  message: string;
}

export interface NewMetricAlert {
  title: string; // at most 190 characters
  anomalyCondition: 'any' | 'high' | 'low';
  for?: string; // duration, e.g. "5m"
  threshold?: string; // e.g. '>0.7' alerts if more than 70% of the points in the window are anomalous. Requires "window" to be set
  window?: string; // duration, e.g. "5m"
  labels?: { [key: string]: string }; // labels to be added to the alert
  annotations?: { [key: string]: string }; // annotations to be added to a firing alert
  noDataCondition?: 'OK' | 'Alerting' | 'NoData'; // default is "OK"
}

export type MetricAlert = UnnamedMetadata & NewMetricAlert;

export enum TimeUnit {
  Minutes,
  Hours,
  Days,
  Years,
}

export interface FullFormModel {
  name: string;
  metric: string;
  description?: string;
  query: { key: string; value?: DataQuery };
  parameters: ModelForm;
  holidays?: string[];
}

/**
 * Possible values for the 'ml_forecast' label in series returned by the MLAPI.
 */
export type ForecastLabelValue = 'y' | 'yhat_lower' | 'yhat_upper' | 'yhat';

/**
 * The names of the four series required to plot the results of a job for
 * a single underlying series.
 */
export interface JobSeriesNames {
  // The name of the 'actual' series (i.e. the series with ml_forecast="y").
  actual: string;
  // The name of the 'predicted' series (i.e. the series with ml_forecast="yhat").
  predicted: string;
  // The name of the series containing the predicted lower bound
  // (i.e. the series with ml_forecast="yhat_lower").
  lower: string;
  // The name of the series containing the predicted upper bound
  // (i.e. the series with ml_forecast="yhat_upper").
  upper: string;
}

/**
 * The queries which should be used to access the results of a job.
 *
 * These queries will include:
 * - the job name
 * - either `predicted` or `actual`
 * - the `ml_job_id` selector, to guarantee uniqueness.
 * - possibly, additional selectors, to filter down to only one of many series.
 */
export interface JobMetricQueries {
  // The 'actual' query.
  actual: string;
  // The 'predicted' query.
  predicted: string;
}

/**
 * Information about one 'series' underlying a job.
 */
export interface JobSeriesInfo {
  // The names of the various actual and predicted series.
  names: JobSeriesNames;
  // The labels common to this series.
  labels: Labels;
}

/**
 * Mapping from series name to series type.
 */
export interface NameToType {
  [index: string]: ForecastLabelValue;
}

/**
 * Mapping from series name to series labels.
 */
export interface NameToLabels {
  [index: string]: Labels;
}

/**
 * Mapping from label name to all observed label values.
 */
export interface LabelValues {
  [index: string]: string[];
}

export interface JsonObject {
  [key: string]: Json;
}

type JsonArray = Json[];

/**
 * something declared as JSON can not contain non-json things like functions or classes with functions
 */
type Json = JsonArray | JsonObject | boolean | number | string | null | undefined;

/*
 **** Helpers that do the right thing for types. commented out since we're not using and i'm cutting unused dependencies, but these might be used in future
function isJsonBool(value: Json): value is boolean {
  return value != null && (value === true || value === false);
}

function isJsonNull(value: Json): value is null {
  return value === null;
}

function isJsonNumber(value: Json): value is number {
  return value != null && typeof value === 'number';
}

function isJsonArray(value: Json): value is Json[] {
  return value != null && value instanceof Array;
}

function isJsonString(value: Json): value is string {
  return value != null && typeof value === 'string';
}

function isJsonObject(value: Json): value is { [key: string]: Json } {
  return !(isJsonBool(value) || isJsonNull(value) || isJsonNumber(value) || isJsonArray(value) || isJsonString(value));
}

function isJsonPrimitive(value: Json): value is boolean | number | string {
  return isJsonBool(value) || isJsonNumber(value) || isJsonString(value);
}

 */

export interface DatadogSLO {
  name: string;
}

export interface FeatureFlags {
  AugursMSTL?: boolean;
  InteractiveProphet?: boolean;
  PowerTransformation?: boolean;
  ShowSiftModalButton?: boolean;
  SIFTDev?: boolean;
  SIFTExperimental?: boolean;
  SIFTDeprecated?: boolean;
  SIFTDummy?: boolean;
  SIFTPreview?: boolean;
  SIFTNewView?: boolean;
}

/// Tenant information from grafana.com
///
/// A 'tenant' is a Hosted Grafana instance with machine learning
/// enabled, belonging to an org which may or may not have a paying subscription.
///
/// The `canAccess` field indicates whether the user should be able to use the
/// ML functionality, or whether a 'free user' experience should be given instead.
export interface TenantInfo {
  /// The ID of the Hosted Grafana instance in grafana.com
  id: number;
  /// The name of the Hosted Grafana instance in grafana.com
  name: string;
  /// Whether the user has access to Grafana ML.
  canAccess: boolean;
  /// The features enabled for this tenant.
  features?: FeatureFlags;
  /// The maximum number of series allowed per forecast for this tenant.
  maxSeriesPerJob: number;
  /// The maximum number of series allowed per outlier for this tenant.
  maxSeriesPerOutlier: number;
}

/// Annotations added to an alert or alert rule.
type Annotations = Record<string, string>;

interface AlertRuleDataSource {
  /// The ID of the datasource used for the alerting query.
  id: number;
  /// The type of the datasource used for the alerting query.
  type: string;
}

/// Common interface shared by all 'model's in alert rules.
interface AlertRuleDataModel {
  refId: string;
  datasource?: AlertRuleDataSource;
  intervalMs: number;
  maxDataPoints: number;
}

/// The model for a Prometheus query in an alert rule.
interface PrometheusQueryModel extends AlertRuleDataModel {
  exemplar?: boolean;
  expr: string;
  interval: string;
  legendFormat: string;
}

/// The model for a 'classic condition' in an alert rule.
interface ExpressionModel extends AlertRuleDataModel {
  /// The conditions for this query.
  ///
  /// Currently untyped because I don't think we'll need them and they're complex.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conditions: any[];
  /// Whether to hide this query in the UI.
  hide: boolean;
  /// The type of this expression.
  type: string;
}

/// A time range relative to the current time.
interface RelativeTimeRange {
  /// The number of seconds to look back prior to `now()`.
  from: number;
  /// The number of seconds to look forward from `now()`.
  to: number;
}

/// The main body of a Grafana Alert Rule, defining queries.
interface GrafanaAlertRuleData {
  /// The refId of the query. refIds are unique within a rule.
  ///
  /// This will generally be something like 'A' or 'B'.
  refId: string;

  /// The type of query.
  ///
  /// This has been "" in all examples I have seen so far.
  queryType: string;

  /// The time range to use for charts in the UI when editing the query.
  relativeTimeRange: RelativeTimeRange;

  /// The UID of the datasource being queried.
  ///
  /// This will be meaningless for 'expression' queries.
  datasourceUid: string;

  /// The body of the query. This will differ depending on the selected datasource type.
  model: ExpressionModel | PrometheusQueryModel;
}

/// A 'Grafana Alerting' alert rule, created using the alerting UI or API.
///
/// This is the interface for alerts of type 'grafana_alert'.
interface GrafanaAlertRule {
  /// The ID of the rule.
  id: number;
  /// The org ID to which the rule belongs.
  orgId: number;
  /// The title of the rule.
  title: string;
  /// The refId of the queries in `data` which is used as the condition for the alert to fire.
  condition: string;
  /// The queries used to calculate the alert's state.
  data: GrafanaAlertRuleData[];
  /// The time that the rule was last updated.
  updated: string;
  /// How often the alert will be evaluated to see if it fires.
  intervalSeconds: number;
  /// A monotonic integer indicating the version of the alert.
  version: number;
  /// The ID of the alert rule.
  uid: string;
  /// The UID of namespace of the alert rule in the ruler.
  // eslint-disable-next-line camelcase
  namespace_uid: string;
  /// The ID of namespace of the alert rule in the ruler.
  // eslint-disable-next-line camelcase
  namespace_id: string;
  /// The name of the rule group in which this alert rule lives.
  // eslint-disable-next-line camelcase
  rule_group: string;
  /// The state to use if no data can be found or if all values are null.
  // eslint-disable-next-line camelcase
  no_data_state: string;
  /// The state to use if alert execution fails or times out.
  // eslint-disable-next-line camelcase
  exec_err_state: string;
}

/// An alert rule.
///
/// This may have additional possible keys when using non-Grafana alerts,
/// but we only care about Grafana Alerting.
interface AlertRule {
  expr: string;
  /// The duration for which the alert rules must be pending before
  /// the status changes to 'firing'.
  for: string;
  /// Annotations on the alert rule.
  annotations: Annotations;
  /// The Grafana Alert rule.
  // eslint-disable-next-line camelcase
  grafana_alert: GrafanaAlertRule;
}

/// An alert, consisting of a name and a list of rules.
export interface Alert {
  /// The name of this alert.
  name: string;
  /// How often the alert will be evaluated to see if it fires.
  interval: string;
  /// The rules making up this alert.
  rules: AlertRule[];
}

/// The current state of an alert.
export type AlertState = 'firing' | 'Normal' | 'Pending';

/// A live alert instance, matching a single series.
///
/// Each alert rule created will result in one `AlertInstance` per matching series,
/// which will have its own individual state depending on the values of the series.
export interface AlertInstance {
  /// The state of this alert instance.
  state: AlertState;
  /// Unknown - seems to be the empty string everywhere.
  value: string;
  /// The timestamp that this alert was last active.
  ///
  /// Equal to '0001-01-01T00:00:00Z' if the alert has never fired.
  activeAt: string;
  /// Annotations, propagated from the alert rule.
  annotations: Annotations;
  /// Labels found on the matching series.
  labels: Record<string, string>;
}

/// The current state of an alert _rule_.
export type RuleState = 'firing' | 'inactive' | 'pending';

/// A 'live' alert rule instance, comprising a list of live alerts instances and some metadata.
export interface RuleInstance {
  /// The state of this rule.
  ///
  /// This will be:
  /// - 'firing' if any contained alert is firing, else
  /// - 'pending' if any contained alert is pending, else
  /// - 'inactive'.
  state: RuleState;
  /// The name of this rule. This generally matches the name of the alert group.
  name: string;
  /// The query used to match series and create AlertInstances.
  query: string;
  /// The number of seconds for which any alert must be pending before it begins firing.
  ///
  /// AKA the 'for' clause on an alert rule.
  duration: number;
  /// The alerts created by this rule.
  alerts: AlertInstance[];
  /// Annotations added to the rule at creation time.
  ///
  /// These will also be added to any alerts.
  annotations?: Annotations;
  labels: unknown;
  /// The health of this rule instance.
  ///
  /// Will be 'ok' if everything is working fine.
  health: string;
  /// The last error returned when trying to evaluate the rule.
  ///
  /// Will be the empty string if no error occurred.
  lastError: string;
  /// The type of this rule.
  ///
  /// This is equal to 'alerting' for alerting rules.
  type: string;
  /// Timestamp that the rule was last evaluated.
  lastEvaluation: string;
  /// The duration, in seconds, that this rule took to evaluate.
  evaluationTime: number;
}

/// A named group of existing alert rules residing in a folder.
export interface AlertGroup {
  /// The name of this alert group.
  ///
  /// This is the name given to the group at creation time.
  name: string;
  /// The name or path to the folder in which this alert lives.
  file: string;
  /// The frequency with which the rules in this group are evaluated, in seconds.
  interval: number;
  /// The timestamp at which this alert group was last evaluated.
  lastEvaluation: string;
  /// The duration, in seconds, that this rule took to evaluate.
  ///
  /// Note that for Grafana alerts this number is not always accurate; use the
  /// 'evaluationTime' on the individual rules instead.
  evaluationTime: number;
  /// The rules comprising this alert group.
  rules: RuleInstance[];
}

export interface Holiday {
  id: string;
  name: string;
  description?: string;
  iCalUrl?: string;
  iCalTimeZone?: string;
  customPeriods?: HolidayOccurrence[];
  resolvedDates?: HolidayOccurrence[];
  jobs?: string[];
}

export interface HolidayOccurrence {
  startTime: string;
  endTime: string;
  name?: string;
}

export interface NewHoliday {
  name: string;
  description?: string;
  iCalUrl?: string;
  iCalTimeZone?: string;
  customPeriods?: HolidayOccurrence[];
  jobs?: string[];
}

export interface SiftDatasource {
  uid: string;
  inputs?: LabelInput[];
}

export interface DatasourceConfig {
  lokiDatasource: SiftDatasource;
  prometheusDatasource: SiftDatasource;
  tempoDatasource: SiftDatasource;
  pyroscopeDatasource?: SiftDatasource;
}

export interface AnalysisCounts {
  total: number;
  pending: number;
  running: number;
  succeeded: number;
  failed: number;
  interesting: number;
  skipped: number;
}

export interface ReducedAnalysis {
  id: string;
  name: string;
  status: AnalysisStatus;
  stage: CheckStage;
  interesting: boolean;
}

export interface AnalysisMetadata {
  countsByStage: Partial<Record<CheckStage, AnalysisCounts>>;
  items: ReducedAnalysis[];
}

type InvestigationStatus = 'failed' | 'pending' | 'running' | 'finished';

export type Investigation = NewInvestigation &
  UnnamedMetadata & {
    datasources: DatasourceConfig;
    analyses: AnalysisMetadata;
    timeRange: TimeRange;
    status: InvestigationStatus;
    retryable?: boolean;
    failureReason?: string;
  };

/// Represent a mutation or creation of a metric job
export interface NewInvestigation {
  name: string;
  grafanaUrl?: string;
  requestData: InvestigationRequest;
  inputs?: Input[];
}

export enum InvestigationSourceType {
  AlertManager = 'alertmanager',
  AppO11yOperation = 'app-o11y/operation',
  AppO11yService = 'app-o11y/service',
  CommandPalette = 'command-palette',
  Explore = 'explore',
  Incident = 'incident',
  KubernetesCluster = 'kubernetes/cluster',
  KubernetesNamespace = 'kubernetes/namespace',
  KubernetesPod = 'kubernetes/pod',
  KubernetesWorkload = 'kubernetes/workload',
  ML = 'ml',
  OnCallUI = 'oncall-ui',
  OnCallIntegration = 'oncall-integration',
  Panel = 'panel',
  Rerun = 'rerun',
  Unknown = 'unknown',
}

export interface InvestigationSource {
  type: InvestigationSourceType;
  id: string;
  url: string;
}

export interface InvestigationRequest {
  labels: Record<string, string>;
  queryUrl?: string;

  start: string;
  end: string;

  investigationSource: InvestigationSource;
}

export interface Label {
  name: string;
  value: string;
  type?: MatchType;
}

// Sift clue/input types.

type ClueTypeAlert = 'alert';
type ClueTypeGrafanaQuery = 'grafana-query';
type ClueTypeOnCallAlertGroup = 'on-call-alert-group';

interface AlertClue {
  type: ClueTypeAlert;
  payload: {
    labels: Record<string, string>;
    generatorURL?: string;
  };
}

interface GrafanaQueryClue {
  type: ClueTypeGrafanaQuery;
  payload: DataQuery;
}

interface OnCallAlertGroupClue {
  type: ClueTypeOnCallAlertGroup;
  payload: OnCallAlertGroup;
}

export type Clue = AlertClue | GrafanaQueryClue | OnCallAlertGroupClue;

export enum MatchType {
  Equal = 0,
  NotEqual = 1,
  Regexp = 2,
  NotRegexp = 3,
}

export interface Service {
  name: string;
  namespace?: string;
}

export interface SLO {
  uuid: string;
}

export type InputTypeLabel = 'label';
export type InputTypeTimeRange = 'time-range';
export type InputTypeMetricQuery = 'metric-query';
export type InputTypeService = 'service';
export type InputTypeDataSource = 'datasource';
export type InputTypeSLO = 'slo';
// type InputType = InputTypeLabel | InputTypeTimeRange | InputTypeTraceID;

export interface LabelInput {
  type: InputTypeLabel;
  label: Label;
}

export interface TimeRangeInput {
  type: InputTypeTimeRange;
  timeRange: RawTimeRange;
}

export interface MetricQueryInput {
  type: InputTypeMetricQuery;
  metric: DataQuery;
}

export interface ServiceInput {
  type: InputTypeService;
  service: Service;
}

export interface DataSourceInput {
  type: InputTypeDataSource;
  datasource: DataSourceRef;
}

export interface SLOInput {
  type: InputTypeSLO;
  slo: SLO;
}

export type Input = LabelInput | MetricQueryInput | TimeRangeInput | ServiceInput | DataSourceInput | SLOInput;

export interface InvestigationFormModel {
  name: string;

  start: string;
  end: string;

  labels: Label[];
  alertQuery?: string;

  queries: DataQuery[];

  investigationSource: InvestigationSource;

  services?: Service[];

  datasources?: DataSourceRef[];

  slos?: SLO[];
}

export type AnalysisStatus = 'finished' | 'pending' | 'running' | 'skipped';
export type AnalysisDisplayStatus = 'interesting' | 'passed' | 'failed' | 'running' | 'skipped' | 'unknown';
export type AnalysisDisplayCategory = 'application' | 'infrastructure' | 'internal' | 'custom' | 'other';

export type CheckStage = 'Experimental' | 'PublicPreview' | 'GeneralAvailability' | 'Deprecated' | 'Dummy';

export interface AnalysisData {
  investigationId: string;
  name: string;
  status: AnalysisStatus;
  stage: CheckStage;
  title: string;
  result: AnalysisResult;
  config?: Config;
  displayCategory?: AnalysisDisplayCategory;
  displayStatus: AnalysisDisplayStatus;
}

export interface Config {
  lokiDatasourceUid?: string;
  prometheusDatasourceUid?: string;
  tempoDatasourceUid?: string;
  pyroscopeDatasourceUid?: string;
}

export type Analysis = AnalysisData & Metadata;

export interface AnalysisEvent {
  startTime: string;
  endTime: string;
  name: string;
  description?: string;
  details: JsonObject | null;
}

export interface AnalysisResult {
  successful: boolean;
  interesting: boolean;
  message: string;
  details: Json;
  events?: AnalysisEvent[];
}

export interface SiftModalData {
  analysis: Analysis;
  investigation: Investigation;
  datasources: DatasourceConfig;
}

export type Query = DataQuery & {
  expr?: string | undefined;
};

export interface OutlierBufferValue {
  buffer: number[];
}
export interface OutlierLabels {
  isOutlier: string;
  __name__: string;
  [key: string]: string;
}

export interface LabelStats {
  included: boolean;
}

export interface InvestigationPreview {
  analyses: AnalysisData[];
  datasources: DatasourceConfig;
  labels: Record<string, LabelStats>;
  warnings: string[];
  errors: string[];
}
