import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { InfoNumberPill } from '../InfoNumberPill';
import { PatternRecommendation } from '@/api/types';

const getStyles = (theme: GrafanaTheme2) => ({
  currentRate: css({
    gridColumnEnd: '2',
    gridColumnStart: '2',
  }),
  getCurrentRateContainer: (locked = false) => {
    return css({
      alignItems: 'center',
      color: locked ? theme.colors.text.secondary : undefined,
      display: 'inline-grid',
      gridTemplateColumns: `${theme.spacing(2.5)} ${theme.spacing(4.5)} ${theme.spacing(4)}`,
      justifyItems: 'center',
    });
  },
});

export const CurrentRate = ({ recommendation }: { recommendation: PatternRecommendation }) => {
  const { configured_drop_rate } = recommendation;
  const styles = useStyles2(getStyles);

  const overridePill = useMemo(() => {
    if (!recommendation.segments) {
      return null;
    }

    const overrideCount = Object.keys(recommendation.segments).length;
    if (!overrideCount) {
      return null;
    }

    const tooltip =
      overrideCount === 1 ? `${overrideCount} per-service drop rate` : `${overrideCount} per-service drop rates`;

    return <InfoNumberPill tooltip={tooltip} value={overrideCount} />;
  }, [recommendation.segments]);

  return (
    <div className={styles.getCurrentRateContainer(recommendation.locked)}>
      {recommendation.locked && <Icon data-testid="current-rate-icon" name="lock" />}
      <div data-testid="current-rate-text" className={styles.currentRate}>
        {configured_drop_rate}%
      </div>
      {overridePill}
    </div>
  );
};
