import React, { useContext, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { flatten as lodashFlatten } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, MultiSelect, useStyles2 } from '@grafana/ui';

import { QueryFrequencyFilter } from '../QueryIngestRatio';
import { BatchEditPattern } from './BatchEditPattern';
import { FilterField } from './FilterField';
import { ApplyModal } from '@/components/ApplyModal';
import { ContentBox } from '@/components/ContentBox';
import { AdaptiveLogsContext } from '@/context/adaptive-logs-context';
import { useRecommendations } from '@/hooks/api-hooks';
import { useSelectedItems, useServiceNameFilter, useTableData, useUserPermissions } from '@/hooks/context-hooks';
import { getServiceNameFromSegment } from '@/utils/segments';

// Partition the spans over the DIVISIONS
const DIVISIONS = 10;
// ROW 1
const SEGMENT_FILTER = 3;
const PATTERN_FILTER = 5;
const FREQUENCY_FILTER = 2;
// ROW 2
const TEXT_CONTAINER = 3;
const BUTTONS = 7;

const getStyles = (theme: GrafanaTheme2, canApplyPatterns: boolean) => {
  return {
    base: css({
      marginTop: theme.spacing(1),
    }),
    buttonContainer: css({
      alignItems: 'flex-end',
      display: 'flex',
      gap: theme.spacing(1),
      gridColumn: `span ${BUTTONS}`,
      justifyContent: 'flex-end',
    }),
    /**
     * TODO: A PR https://github.com/grafana/grafana/pull/93858 for this is merged in the
     * grafana/grafana repo we will wait for the update on that and apply the
     * validationMessageHorizontalOverflow prop in the InlineField instead of having this css
     *  */
    errorFix: css`
      div[role='alert'] {
        position: fixed;
      }
    `,
    filters: css({
      display: 'grid',
      gap: theme.spacing(1),
      gridTemplateColumns: `repeat(${DIVISIONS}, 1fr)`,
    }),
    frequencyFilter: css({
      gridColumn: `span ${FREQUENCY_FILTER}`,
      PATTERN_FILTER,
    }),
    fullSpan: css({
      gridColumn: `span ${DIVISIONS}`,
    }),
    maxDropRate: css({
      display: 'grid',
      gridGap: theme.spacing(1),
    }),
    numberInput: css({
      input: {
        textAlign: 'right',
      },
    }),
    patternFilter: css({
      gridColumn: `span ${PATTERN_FILTER}`,
    }),
    patternsCount: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
    }),
    segmentFilter: css({
      gridColumn: `span ${SEGMENT_FILTER}`,
    }),
    selectedText: css({
      color: theme.colors.text.secondary,
      display: 'inline-block',
      fontStyle: 'italic',
      marginRight: theme.spacing(1),
    }),
    textStatusContainer: css({
      gridColumn: `span ${TEXT_CONTAINER}`,
    }),
  };
};

export const PageHeader = () => {
  const [applyOpen, setApplyOpen] = useState(false);
  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles, userPermissions.canApplyPatterns);
  const { serviceNameFilter, setServiceNameFilter } = useServiceNameFilter();
  const { data: recommendations } = useRecommendations();
  const { clearSelection, selectedItems } = useSelectedItems();
  const { tableData } = useTableData();

  const { queryIngestFilter, setQueryIngestFilter } = useContext(AdaptiveLogsContext);

  const serviceNameSegments = useMemo(() => {
    const attributions = recommendations?.items.map((item) => Object.keys(item.attribution));

    return [...new Set(lodashFlatten(attributions))].map((attribution) => {
      const name = getServiceNameFromSegment(attribution);

      return {
        label: name,
        text: name,
        value: attribution,
      };
    });
  }, [recommendations?.items]);

  const applyButtonText = !!selectedItems.size
    ? `Apply selected recommendation${selectedItems.size > 1 ? 's' : ''}`
    : 'Apply all recommendations';

  return (
    <ContentBox className={styles.base}>
      <div className={styles.filters}>
        <Field label="Filter by service" className={styles.segmentFilter}>
          <MultiSelect
            data-testid="service-filter"
            isSearchable
            isClearable
            options={serviceNameSegments}
            value={serviceNameFilter}
            placeholder="All services"
            onChange={(v) => {
              setServiceNameFilter(v);
            }}
          />
        </Field>
        <FilterField className={styles.patternFilter} />
        <QueryFrequencyFilter
          className={styles.frequencyFilter}
          initialValue={queryIngestFilter}
          setFilter={setQueryIngestFilter}
        />
        <div className={styles.textStatusContainer}>
          <div>
            {/* TODO #303 add toggle selected switch */}
            <span className={styles.selectedText}>{`${selectedItems.size} selected`}</span>
            {selectedItems.size > 0 && (
              <Button
                data-testid={'clear-selection-button'}
                variant={'primary'}
                fill={'text'}
                onClick={clearSelection}
                icon={'times-circle'}
                tooltip={'Clear selection'}
              />
            )}
          </div>
          <div className={css(styles.patternsCount, styles.fullSpan)}>
            {tableData.length === recommendations.items.length
              ? `Showing ${tableData.length} patterns`
              : `Showing ${tableData.length} of ${recommendations.items.length} patterns`}
          </div>
        </div>
        {/* TODO #302 refactor this part to bundle the button and the modal */}
        <div className={styles.buttonContainer}>
          {userPermissions.canApplyPatterns && (
            <>
              <BatchEditPattern />
              <Button onClick={() => setApplyOpen(true)}>{applyButtonText}</Button>
            </>
          )}
        </div>
        <ApplyModal
          isOpen={applyOpen}
          onClose={() => {
            setApplyOpen(false);
          }}
        />
      </div>
    </ContentBox>
  );
};
