import { SyntheticEvent } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

// Wrapper to turn useForm handleSubmit function into a response that satisfies typescript
export function onFormSubmit<T>(promise: (event: SyntheticEvent) => Promise<T>) {
  return (event: SyntheticEvent): void => {
    if (promise !== undefined && promise !== null) {
      promise(event).catch(() => null);
    }
  };
}

// handler wrapper
export function onHandler<T>(promise: () => Promise<T>) {
  return (): void => {
    if (promise !== undefined && promise !== null) {
      promise().catch(() => null);
    }
  };
}

// Combine multiple errors into a single string
export function errorsToString<T extends FieldValues>(errors: FieldErrors<T>): string | null {
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return Object.values(errors)
    .map((e) => e?.message)
    .join(', ');
}
