import { DataFrame, DataFrameType, FieldType, PanelData } from '@grafana/data';

import { getMinimumUniqueUnderlyingSeriesLabels } from 'utils';

// Get list of series names from the DataFrame/PanelData. This can deal with any type of DataFrame.
export function getSeriesNames(data: PanelData): string[] {
  const names = [];
  // There are 3 different types of dataframe, need to cover each case.
  switch (detectDataframeType(data.series)) {
    case DataFrameType.TimeSeriesWide:
    // TODO: properly implement this, falling back to Multi logic is not correct!
    // It's pretty rare to see this form however!
    case DataFrameType.TimeSeriesMulti:
      // generate series names by consulting the labels
      const options = getMinimumUniqueUnderlyingSeriesLabels(data);
      for (let i = 0; i < options.length; i++) {
        const name = JSON.stringify(options[i]);
        names.push(name);
      }
      break;
    case DataFrameType.TimeSeriesLong:
      // only the name of each frame can be used
      const theField = data.series[0].fields;
      for (let i = 0; i < theField.length; i++) {
        const field = theField[i];
        // first field should be time, which we ignore
        if (field.type === FieldType.time) {
          continue;
        }
        names.push(field.name);
      }
      break;
  }
  return names;
}

// There are multiple types of DataFrame/PanelData - use heuristics to detect which type
// Ref: https://grafana.github.io/dataplane/timeseries
export function detectDataframeType(data: DataFrame[]): DataFrameType | null {
  if (data.length === 0) {
    return null;
  }

  // Long format is the only one that leaves 'labels' property unused
  // It must have at least 2 fields: time & values
  // Typically returned from SQL-ish datasources.
  if (data[0].fields.length >= 2 && data[0].fields[1].labels === undefined) {
    return DataFrameType.TimeSeriesLong;
  }

  // Wide format has a single Frame and 3 or more Fields (time & 2+ values)
  if (data.length === 1 && data[0].fields.length > 2) {
    return DataFrameType.TimeSeriesWide;
  }
  // Fallback to Multi (used by timeseries like Prometheus)
  return DataFrameType.TimeSeriesMulti;
}

// For a TimeSeriesMulti format PanelData (as Prometheus returns), get index of series in the dataframe
const getSeriesIndexFromName = (data: PanelData, name: string | undefined): number => {
  // Take series to predict on - this is a bit of a faff
  let seriesIndex = -1;
  const options = getMinimumUniqueUnderlyingSeriesLabels(data);
  for (let i = 0; i < options.length; i++) {
    if (name === JSON.stringify(options[i])) {
      seriesIndex = i;
    }
  }
  return seriesIndex;
};

// For any type of DataFrame/PanelData, extract a DataFrame for a single series by its name.
// Names are generated by `getSeriesNames()` above.
export function getSingleSeriesFrameByName(data: PanelData, seriesName: string): DataFrame | null {
  let ds, y;
  switch (detectDataframeType(data.series)) {
    case DataFrameType.TimeSeriesWide:
    case DataFrameType.TimeSeriesMulti:
      const seriesIndex = getSeriesIndexFromName(data, seriesName);
      if (seriesIndex < 0) {
        return null;
      }

      const frame = data.series[seriesIndex];
      ds = frame.fields.find((x) => x.type === FieldType.time);
      y = frame.fields.find((x) => x.type === FieldType.number);
      break;

    case DataFrameType.TimeSeriesLong:
      const theField = data.series[0].fields;
      for (let i = 0; i < theField.length; i++) {
        const field = theField[i];
        if (field.type === FieldType.time) {
          ds = field;
        } else if (field.name === seriesName) {
          y = field;
        }
      }
  }
  if (ds === undefined || y === undefined) {
    return null;
  }

  return { fields: [ds, y], length: 2 };
}
