import uFuzzy from '@leeoniya/ufuzzy';
import {
  every,
  flatten as lodashFlatten,
  isEmpty as lodashIsEmpty,
  toLower as lodashToLower,
  uniq as lodashUniq,
} from 'lodash';

import { PatternRecommendation } from '@/api/types';
import { QueryIngestRange } from '@/components/QueryIngestRatio/types';

const uf = new uFuzzy({
  intraDel: 1,
  intraIns: 1,
  intraMode: 1,
  intraSub: 1,
  intraTrn: 1,
});

/** Returns a list of accepted indices */
export function filterRecommendations(
  recommendations?: PatternRecommendation[],
  filter = '',
  segmentFilterSet?: Set<string>,
  queryIngestFilter?: QueryIngestRange
): number[] | null {
  const isFilterEmpty = lodashIsEmpty(filter);
  const isServiceNameFilterEmpty = !segmentFilterSet || segmentFilterSet.size === 0;

  let acceptedIndices: number[] | null = null;

  if (!recommendations) {
    return null;
  }

  const patternFilters: Array<(pattern: PatternRecommendation) => boolean> = [];
  if (!isServiceNameFilterEmpty) {
    patternFilters.push((pattern) => {
      const attributions = pattern.attribution;
      /**
       * the attribution/segement is of type
       * {service_name="xyz"} : {Volume: 1, Count: 1}
       */
      const segments = lodashFlatten(Object.keys(attributions));

      if (segments.some((segment) => segmentFilterSet.has(segment))) {
        return true;
      }
      return false;
    });
  }

  if (queryIngestFilter) {
    patternFilters.push((pattern) => {
      return pattern.queryIngest.range === queryIngestFilter;
    });
  }

  if (patternFilters.length) {
    acceptedIndices = [];
    for (let index = 0; index < recommendations.length; index++) {
      const pattern = recommendations[index];
      if (every(patternFilters.map((f) => f(pattern)))) {
        acceptedIndices.push(index);
      }
    }
  }

  if (!isFilterEmpty) {
    const SYMBOLS = /["'<>=\[\]{}\*]/;
    const useSimpleFilter = SYMBOLS.test(filter) || filter.length > FILTER_LENGTH_THRESHOLD;
    const patterns = recommendations.map((rec) => rec.pattern);

    if (useSimpleFilter) {
      acceptedIndices = simplerFilter(patterns, filter, acceptedIndices);
    } else {
      acceptedIndices = uf.filter(patterns, filter, acceptedIndices || undefined);
    }
  }

  return acceptedIndices;
}

/** The maximum filter length that we will allow uFuzzy to use.
 *
 *  Larger filter lengths will use `simplerFilter` instead
 */
export const FILTER_LENGTH_THRESHOLD = 25;

function simplerFilter(patterns: string[], filter: string, acceptedIndices: number[] | null) {
  const output: number[] = [];

  // Reduce the filter string into a list of terms that are:
  const terms = lodashUniq(
    // Unique
    filter
      .split(/\s/) // White-space separated
      .map(lodashToLower) // Lower-case
  ).filter((term) => term.length > 0); // Non-empty

  function test(index: number) {
    const pattern = lodashToLower(patterns[index]);
    if (terms.every((term) => pattern.includes(term))) {
      output.push(index);
    }
  }

  if (acceptedIndices === null) {
    for (let i = 0; i < patterns.length; ++i) {
      test(i);
    }
  } else {
    for (let i of acceptedIndices) {
      test(i);
    }
  }
  return output;
}

/**
 * Used in a Array.filter function to only keep objects that are not undefined
 */
export function isDefined<T>(obj: T | undefined): obj is T {
  return obj !== undefined;
}
