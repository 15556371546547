import { ProphetOptionsWithLimits } from 'projects/Forecasting/InteractiveCreate/useQueryProphetResult';
// Constants used throughout the plugin.

import { FullFormModel, TimeUnit } from 'types';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_HOUR_IN_SECONDS = 60 * ONE_MINUTE_IN_SECONDS;
export const ONE_DAY_IN_SECONDS = 24 * ONE_HOUR_IN_SECONDS;
export const ONE_YEAR_IN_SECONDS = 365 * ONE_DAY_IN_SECONDS;

export const MAX_SAMPLES_PER_TRAINING = 50_000;
export const MAX_FORECAST_TRAINING_RANGE_IN_YEARS = 5;

export const PLUGIN_ROOT = '/a/grafana-ml-app';

export const DATASOURCE_NAME = 'grafanacloud-ml-metrics';
export const SUPPORTED_DATASOURCE_TYPES = [
  'grafana-datadog-datasource',
  'graphite',
  'grafana-graphite-datasource',
  'loki',
  'grafana-loki-datasource',
  'prometheus',
  'grafana-prometheus-datasource',
  'grafana-amazonprometheus-datasource',
  'postgres',
  'grafana-postgresql-datasource',
  'doitintl-bigquery-datasource',
  'grafana-bigquery-datasource',
  'grafana-snowflake-datasource',
  'influxdb',
  'grafana-influxdb-datasource',
  'grafana-splunk-datasource',
  'elasticsearch',
  'grafana-elasticsearch-datasource',
  'grafana-mongodb-datasource',
];
export const DEFAULT_MODEL = /^grafana_prophet/;
export const DEFAULT_TRAINING_FREQUENCY_SECONDS = ONE_DAY_IN_SECONDS;
export const DEFAULT_OUTLIER_ALGORITHM = 'dbscan';
export const DEFAULT_SENSITIVITY = 0.5;

export const DEFAULT_NEW_JOB_FORM: FullFormModel = {
  name: '',
  metric: '',
  description: '',
  query: { key: 'A' },
  parameters: {
    id: 'grafana_prophet_1_0_1',
    trainingWindowUnit: TimeUnit.Days,
    trainingWindowValue: 90,
    trainingFrequencyUnit: TimeUnit.Days,
    trainingFrequencyValue: 1,
    intervalUnit: TimeUnit.Minutes,
    intervalValue: 5,
    hyperparameters: {
      // eslint-disable-next-line camelcase
      grafana_prophet_1_0_1: {
        // eslint-disable-next-line camelcase
        changepoint_prior_scale: 0.05,
        // eslint-disable-next-line camelcase
        changepoint_range: 0.8,
        // eslint-disable-next-line camelcase
        seasonality_prior_scale: 10,
        // eslint-disable-next-line camelcase
        holidays_prior_scale: 10,
        // eslint-disable-next-line camelcase
        seasonality_mode: 'additive',
        growth: 'linear',
        // eslint-disable-next-line camelcase
        interval_width: 0.95,
        // eslint-disable-next-line camelcase
        weekly_seasonality: undefined,
        // eslint-disable-next-line camelcase
        conditional_weekly_seasonality: undefined,
        // eslint-disable-next-line camelcase
        daily_seasonality: undefined,
        // eslint-disable-next-line camelcase
        conditional_daily_seasonality: undefined,
        // eslint-disable-next-line camelcase
        logistic_growth_cap: undefined,
        // eslint-disable-next-line camelcase
        logistic_growth_floor: undefined,
        transformation: undefined,
      },
    },
  },
};

export const FORECAST_RENDER_CAP_LIMIT = 20;
export const OUTLIER_RENDER_CAP_LIMIT = 100;

export const FORECAST_STEPS = [
  {
    step: 1,
    title: 'Choose a metric',
    description: 'Create a forecast of this metric',
  },
  {
    step: 2,
    title: 'Preview and tune',
    description: 'Preview forecast of this metric',
  },
  {
    step: 3,
    title: 'Set name and description',
    description: 'Set name and description',
  },
  {
    step: 4,
    title: 'Review',
    description: 'Review details and save forecast',
  },
];

export const INITIAL_MODEL_PARAMETERS: ProphetOptionsWithLimits = {
  growth: 'linear',
  changepoints: undefined, // Do not support user specifying raw changepoint timestamps
  nChangepoints: 25,
  yearlySeasonality: { type: 'manual', enabled: false },
  weeklySeasonality: undefined,
  dailySeasonality: undefined,
  holidays: undefined,
  seasonalityMode: undefined,
  holidaysMode: undefined,
  seasonalityPriorScale: undefined,
  holidaysPriorScale: undefined,
  changepointPriorScale: undefined,
  // mcmcSamples: 0,
  intervalWidth: 0.95,
  uncertaintySamples: 500, // what we use on backend
};
