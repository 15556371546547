import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { satisfies } from 'semver';

import { DisplayValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { PercentChangeColorMode } from '@grafana/schema';
import { BigValue, BigValueColorMode, useTheme2 } from '@grafana/ui';

import { getValueAndUnit } from '@/utils/formats';

// In v11.2.2, `DisplayValue.percentChange` represents 100% by 100, vs. previous versions where it was 1
const oneHundredPercentIs100 = satisfies(config.buildInfo.version, '>=11.2.2', { includePrerelease: true });

const PERCENT_MULTIPLIER = oneHundredPercentIs100 ? 100 : 1;

type Props = {
  bytes: number;
  color: string;
  decimals: number;
  previousBytes?: number;
  title: string;
};

export const SkeletonBytesPanel = ({ title }: { title: string }) => {
  const theme = useTheme2();
  const text = {
    titleSize: Number.parseInt(theme.spacing(1.75), 10),
    valueSize: Number.parseInt(theme.spacing(3), 10),
  };
  const displayValue: DisplayValue = {
    numeric: 0,
    text: (<Skeleton />) as unknown as string,
    title,
  };
  return (
    <div>
      <BigValue value={displayValue} text={text} theme={theme} />
    </div>
  );
};

export const BytesPanel = ({ bytes, color, decimals, previousBytes, title }: Props) => {
  const theme = useTheme2();

  const [value, unit] = getValueAndUnit(bytes, decimals).split(' ');

  const percentChange =
    previousBytes === undefined ? undefined : ((bytes - previousBytes) / previousBytes) * PERCENT_MULTIPLIER;

  const displayValue: DisplayValue = {
    color: theme.visualization.getColorByName(color),
    numeric: bytes,
    percentChange,
    suffix: ` ${unit}`,
    text: value,
    title,
  };

  const text = {
    titleSize: Number.parseInt(theme.spacing(1.75), 10),
    valueSize: Number.parseInt(theme.spacing(3), 10),
  };

  return (
    <BigValue
      value={displayValue}
      colorMode={BigValueColorMode.Value}
      text={text}
      theme={theme}
      percentChangeColorMode={PercentChangeColorMode.Inverted}
    />
  );
};
