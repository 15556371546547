import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { GenerateAPI } from 'components/GenerateAPI';
import { GRAFANA_EXAMPLE_API } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  apiGroup: css({
    width: '700px',
  }),
  tokenForm: css({
    marginBottom: '10px',
  }),
});

type CreateTokenProps = {
  className?: string;
  promId: number;
  successMessage: string;
};

export const CreateToken: FC<CreateTokenProps> = ({ className, promId, successMessage }) => {
  const styles = useStyles2((theme) => getStyles(theme));

  return (
    <div className={cx(styles.tokenForm, className)}>
      <p>
        A Grafana Cloud token is necessary for authentication when sending telemetry data to Grafana Cloud. The provided
        token should be kept secure and used in your application&lsquo;s environment variables for authorization.
      </p>
      <div className={styles.apiGroup}>
        <p>Generate Grafana Cloud Token</p>
        <GenerateAPI
          shouldShowClipboard={true}
          shouldShowInstruction={false}
          config={GRAFANA_EXAMPLE_API}
          instanceId={promId}
          successMessage={successMessage}
        />
      </div>
    </div>
  );
};
