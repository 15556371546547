import React, { useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import { inRange as lodashInRange, toString as lodashToString } from 'lodash';

import { anyToNumber, GrafanaTheme2 } from '@grafana/data';
import { Button, Checkbox, ConfirmModal, Field, Input, Stack, useStyles2 } from '@grafana/ui';

import { PatternRecommendation, PatternUpdateModel } from '@/api/types';
import { Bytes } from '@/components/Bytes';
import { getSummaryVolumes } from '@/components/SavingsPreviewPanels/utils';
import { SegmentEditor } from '@/components/SegmentEditor';
import { useSegmentEditorState } from '@/components/SegmentEditor/hooks';
import { useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useUserPermissions } from '@/hooks/context-hooks';
import { useSegments } from '@/hooks/segment-hooks';
import {
  DROP_RATE_UPPER_LIMIT_EXCLUSIVE,
  ERROR_DROP_PERCENTAGE_RANGE,
  UPDATED_RATE_INPUT_WIDTH,
} from '@/utils/constants';
import { formatNumber, getValueAndUnit } from '@/utils/formats';
import { noop } from '@/utils/methods';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  currentRateContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  }),
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  modalText: css({
    ...theme.typography.body,
    color: theme.colors.text.secondary,
    paddingBottom: theme.spacing(1.5),
  }),

  updatedRateField: css({
    marginTop: theme.spacing(2),
  }),
});

export const EditPattern = ({ recommendation }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [locked, setLocked] = useState(false);
  const [updatedRate, setUpdatedRate] = useState(`${recommendation.configured_drop_rate}`);
  const { isPending: recommendationUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();
  const styles = useStyles2(getStyles);
  const userPermissions = useUserPermissions();

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // When switching isOpen to true, focus on input
    isOpen && ref.current?.focus();
  }, [isOpen]);

  const segments = useSegments(recommendation);

  const editableSegments = useMemo(
    () => segments.map((segment) => ({ ...segment, dropRate: lodashToString(segment.configured_drop_rate) })),
    [segments]
  );
  const {
    errorSegments,
    modifiedSegments,
    reset: resetSegments,
    setSegmentDropRate,
    updates: segmentUpdates,
  } = useSegmentEditorState(editableSegments);

  const onConfirm = async () => {
    const recommendationsToUpdate: PatternUpdateModel[] = [
      {
        ...recommendation,
        configured_drop_rate: anyToNumber(updatedRate),
        locked,
        segments: segmentUpdates.current,
      },
    ];
    await updateRecommendationsAsync(recommendationsToUpdate);
    setIsOpen(false);
  };

  const dropRateOutOfRange = !lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE);

  const volumeDropped = useMemo(() => {
    const { totalDropped } = getSummaryVolumes([recommendation]);
    return totalDropped;
    // const volumeAffectableByPatternDropRate = getVolumeAffectableByPatternDropRate([recommendation]);
    // const droppedBySegments = sum(segments.map(({droppedVolume}) => droppedVolume))
    // return {volumeAffectableByPatternDropRate, volumeDropped: totalDropped + droppedBySegments}
  }, [recommendation]);

  const parsedDropRate = anyToNumber(updatedRate);

  const modifiedDroppedVolume = calculateModifiedDroppedVolume();

  function calculateModifiedDroppedVolume() {
    const updates = segmentUpdates.current;
    if (!isOpen || !updates || isNaN(parsedDropRate)) {
      return null;
    }

    let volume = 0;
    for (const segment of segments) {
      const update = updates[segment.segment];

      // First use the updated rate from the segment editor

      let dropRate = update?.configured_drop_rate;

      if (dropRate === undefined && update == null) {
        // If that's not set, use the original drop rate from segment
        // Unless of course, it's an update that made it null
        dropRate = segment.configured_drop_rate;
      }
      if (dropRate === undefined) {
        // If it's still not set, default to the pattern drop rate from editor
        // This `parsedDropRate` will either be the original, or the edited.
        dropRate = parsedDropRate;
      }

      // Add the updated dropped volume for this segment
      const updatedDrop = (segment.totalVolume * dropRate) / 100;
      volume += updatedDrop;
    }
    return volume;
  }

  return (
    <>
      <Button
        tooltip={
          !userPermissions.canApplyPatterns
            ? 'You do not have permission for this action.'
            : "Edit pattern's drop rate."
        }
        icon="pen"
        aria-label="individual-pattern-edit"
        variant="secondary"
        size="sm"
        disabled={!userPermissions.canApplyPatterns}
        onClick={() => {
          setLocked(recommendation.locked);
          resetSegments();
          setIsOpen(true);
        }}
      />
      <ConfirmModal
        title="Edit pattern drop rate"
        isOpen={isOpen}
        body={
          <div className={styles.modalBody}>
            <div className={styles.modalText}>
              {`Set up drop rate for the selected pattern. ` +
                `The drop rate is ${recommendation.configured_drop_rate}% ` +
                `with ${getValueAndUnit(volumeDropped, 2)} of projected savings.`}
            </div>
            <Stack alignItems={'flex-end'}>
              <Field
                label="Pattern drop rate"
                description="Default"
                disabled={locked}
                invalid={dropRateOutOfRange}
                error={ERROR_DROP_PERCENTAGE_RANGE}
              >
                <Input
                  ref={ref}
                  aria-label="Pattern drop rate"
                  data-testid="pattern-drop-rate-input"
                  width={UPDATED_RATE_INPUT_WIDTH}
                  type="number"
                  value={updatedRate}
                  suffix="%"
                  onFocus={(event) => event.target.select()}
                  onChange={(v) => {
                    setUpdatedRate(v.currentTarget.value);
                  }}
                />
              </Field>
              <Field>
                <Checkbox
                  value={locked}
                  onClick={(event) => setLocked(event.currentTarget.checked)}
                  label="Lock this pattern drop rate"
                />
              </Field>
            </Stack>
            <div className={styles.modalText}>
              You can define values per service for this pattern. Leave blank to fall back to the default value. This
              feature is currently in preview.
            </div>
            <SegmentEditor
              errorSegments={errorSegments}
              modifiedSegments={modifiedSegments}
              setSegmentDropRate={setSegmentDropRate}
              rightColumnHeader="% (volume)"
              getPlaceholderText={() => (updatedRate && updatedRate.trim() !== '' ? updatedRate.trim() : undefined)}
              rightColumnRenderCell={(segment) => (
                <span style={{ display: 'flex' }}>
                  {formatNumber((segment.Volume / recommendation.volume) * 100, 2)}
                  {'% '}
                  (<Bytes bytes={segment.Volume} />)
                </span>
              )}
              segments={editableSegments}
            />
            {modifiedDroppedVolume !== null && (
              <div className={styles.modalText}>
                Projected savings after you apply changes: {getValueAndUnit(modifiedDroppedVolume, 2)}
              </div>
            )}
          </div>
        }
        confirmText={recommendationUpdating ? 'Applying...' : 'Apply'}
        disabled={!updatedRate || dropRateOutOfRange || !!errorSegments.size}
        confirmButtonVariant={recommendationUpdating ? 'secondary' : 'primary'}
        onConfirm={recommendationUpdating ? noop : onConfirm}
        onDismiss={
          recommendationUpdating
            ? noop
            : () => {
                setUpdatedRate(`${recommendation.configured_drop_rate}`);
                setIsOpen(false);
              }
        }
      />
    </>
  );
};
