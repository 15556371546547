import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Counter, Tooltip, useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  noTextSelect: css({ userSelect: 'none' }),
});

type Props = {
  /** Tool tip content */
  tooltip: string;
  /** Number to display */
  value: number;
};

export const InfoNumberPill = ({ tooltip, value }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <Tooltip content={tooltip} placement="right">
      <span className={styles.noTextSelect}>
        <Counter value={value} />
      </span>
    </Tooltip>
  );
};
