import { PluginExtensionPanelContext } from '@grafana/data';

import { SiftModalContext } from 'projects/Sift/SiftModal';

import { PluginExtensionExploreContext } from './types';

export function isPanelContext(context: object | undefined): context is PluginExtensionPanelContext {
  return Boolean(context && 'pluginId' in context && 'targets' in context);
}

export function isExploreContext(context: object | undefined): context is PluginExtensionExploreContext {
  return Boolean(context && 'exploreId' in context && 'targets' in context && 'timeRange' in context);
}

export function assertExploreOrPanelContext(
  context: object | undefined
): asserts context is PluginExtensionExploreContext | PluginExtensionPanelContext {
  if (!isExploreContext(context) && !isPanelContext(context)) {
    throw new Error(
      'Extension context does not match type: "PluginExtensionExploreContext" or "PluginExtensionPanelContext"'
    );
  }
}

export function isSiftModalContext(context: object | undefined): context is SiftModalContext {
  return Boolean(context && 'analysisId' in context && 'investigationId' in context);
}

export function assertSiftModalContext(context: object | undefined): asserts context is SiftModalContext {
  if (!isSiftModalContext(context)) {
    throw new Error('Extension context does not match type: "SiftModalContext"');
  }
}
