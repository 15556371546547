import React, { type ComponentClass, Suspense, lazy } from 'react';

import { GrafanaInternalApiClient } from '@grafana-incident-app/api/GrafanaInternalApiClient';
import type { GrafanaIncidentSettings } from '@grafana-incident-app/types';
import { setClient } from '@grafana-irm/core/client';
import { addExtensionLink, exposeComponent, getIsIrmPluginPresent } from '@grafana-irm/core/plugin';
import { AppPlugin, type AppPluginMeta, type AppRootProps, type PluginConfigPageProps } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Spinner } from '@grafana/ui';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

setClient(new GrafanaInternalApiClient());

const MinimalAppContextProvider = lazy(() => import('@grafana-irm/incident-state/MinimalAppContextProvider'));
const App = lazy(() => import('@grafana-incident-app/pages/App'));
const AppConfig = lazy(() => import('@grafana-incident-app/pages/AppConfig'));
const ServiceCenterIncidentTable = lazy(() => import('@grafana-incident-app/components/ServiceCenterTable'));

if ('serviceWorker' in navigator) {
  /**
   * Service Workers: An Introduction and Purpose in This Project
   *
   * A service worker is a script run by the browser in the background, separate from the web page,
   * enabling features like asset caching, offline access, push notifications, and background syncing.
   *
   * In this project, we are registering a service worker to achieve the following goals:
   *
   * 1. Old version access:
   *    Service workers allow us to cache assets and data, enabling the application to work when the
   *    server no longer has our application.
   *
   * 2. **Performance Optimization**:
   *    By caching essential assets, we can significantly improve the loading time of our application,
   *    making it more responsive and faster to load on repeat visits.
   *
   * NOTE: Service workers require a secure origin (HTTPS) and are not fully supported in older browsers.
   * NOTE: We use workbox-webpack-plugin to generate our service-worker.js which supports dev mode and many other great features!
   */
  navigator.serviceWorker
    .register(`/public/plugins/grafana-incident-app/service-worker.js?v=${process.env.CURRENT_VERSION}`)
    .then((registration) => {
      console.info('ServiceWorker registration successful with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('ServiceWorker registration failed:', error);
    });
} else {
  console.error('ServiceWorker registration failed, no navigator.serviceWorker');
}

function AppWithLoader(props: AppRootProps<GrafanaIncidentSettings>): React.ReactElement {
  const queryClient = new QueryClient();
  return (
    <Suspense fallback={<PluginPage children={<Spinner />} />}>
      <QueryClientProvider client={queryClient}>
        <MinimalAppContextProvider>
          <App {...props} />
        </MinimalAppContextProvider>
      </QueryClientProvider>
    </Suspense>
  );
}

// NOTE: Used by grafana code, so not an used export
// ts-prune-ignore-next
const plugin = new AppPlugin<GrafanaIncidentSettings>()
  .setRootPage(AppWithLoader as unknown as ComponentClass<AppRootProps<GrafanaIncidentSettings>, unknown>)
  .addConfigPage({
    title: 'Configuration',
    icon: 'cog',
    body: AppConfig as unknown as ComponentClass<
      PluginConfigPageProps<AppPluginMeta<GrafanaIncidentSettings>>,
      unknown
    >,
    id: 'incident-configuration',
  });

async function getDeclareIncidentOnClick(shouldAttachURL = false) {
  const mod = await import('./utils/getDeclareIncidentClickHandler');
  return mod.getDeclareIncidentClickHandler({ shouldAttachURL });
}

if (!getIsIrmPluginPresent()) {
  addExtensionLink<GrafanaIncidentSettings>(plugin, {
    title: 'Declare incident',
    description: 'Declare an incident and attach the panel context to it',
    category: 'Incident',
    icon: 'fire',
    targets: ['grafana/dashboard/panel/menu'],
    onClick: async (...args) => {
      const onClickHandler = await getDeclareIncidentOnClick(true);
      await onClickHandler(...args);
    },
  });

  addExtensionLink<GrafanaIncidentSettings>(plugin, {
    title: 'Declare an incident', // titles of each extension need to be unique
    description: 'Declare an incident',
    targets: ['grafana/explore/toolbar/action'],
    category: 'Incident',
    icon: 'fire',
    onClick: async (...args) => {
      const onClickHandler = await getDeclareIncidentOnClick();
      await onClickHandler(...args);
    },
  });
  exposeComponent(plugin, {
    id: 'grafana-incident-app/service-center-table-component/v1',
    title: 'Service center table component',
    description: 'Service center table component',
    component: () => {
      const queryClient = new QueryClient();
      return (
        <Suspense>
          <QueryClientProvider client={queryClient}>
            <ServiceCenterIncidentTable></ServiceCenterIncidentTable>
          </QueryClientProvider>
        </Suspense>
      );
    },
  });
}

export { plugin };
