import React, { useState } from 'react';

import { Button, ConfirmModal, Field, Input, Tooltip } from '@grafana/ui';

import { Exemption } from '@/api/types';
import { useCreateExemptionMutation, useUpdateExemptionMutation } from '@/hooks/api-hooks';
import { noop } from '@/utils/methods';

interface Props {
  exemption?: Exemption;
}

export const AddEditExemption = ({ exemption }: Props) => {
  const [streamSelector, setStreamSelector] = useState('');
  const [reason, setReason] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { isPending: isExemptionCreating, mutateAsync: createExemptionAsync } = useCreateExemptionMutation();
  const { isPending: isExemptionUpdating, mutateAsync: updateExemptionAsync } = useUpdateExemptionMutation();

  const onConfirm = async () => {
    const payload: Partial<Exemption> = {
      id: exemption?.id,
      reason,
      stream_selector: streamSelector,
    };

    if (payload.id) {
      await updateExemptionAsync(payload);
    } else {
      await createExemptionAsync(payload);
      setStreamSelector('');
    }

    setReason('');
    setIsOpen(false);
  };

  const busy = isExemptionCreating || isExemptionUpdating;

  return (
    <div>
      <Button
        aria-label={exemption ? 'Edit exemption' : 'Add new exemption'}
        variant={exemption ? 'secondary' : 'primary'}
        icon={exemption ? 'pen' : undefined}
        size={exemption ? 'sm' : undefined}
        onClick={() => {
          setStreamSelector(exemption?.stream_selector || '');
          setReason(exemption?.reason || '');
          setIsOpen(true);
        }}
      >
        {exemption ? '' : 'Add new exemption'}
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title="Add exemption"
        body={
          <div>
            <Field label="Stream selector">
              <Tooltip content="Enter stream selector in the format of Loki's log stream selector">
                <Input
                  data-testid="stream-selector-input"
                  value={streamSelector}
                  placeholder='E.g. {container="query-frontend",namespace="loki-dev"}'
                  onChange={(v) => setStreamSelector(v.currentTarget.value)}
                />
              </Tooltip>
            </Field>
            <Field label="Reason">
              <Input placeholder="Optional" value={reason} onChange={(v) => setReason(v.currentTarget.value)} />
            </Field>
          </div>
        }
        confirmText={busy ? 'Saving...' : 'Save'}
        onConfirm={busy ? noop : onConfirm}
        onDismiss={
          busy
            ? noop
            : () => {
                setIsOpen(false);
                setReason('');
              }
        }
        confirmButtonVariant="primary"
      />
    </div>
  );
};
