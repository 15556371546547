import type { Client, ClientResponse, GrafanaPluginFetchResponse, Json, JsonObject } from '@grafana-irm/core/types';
import type { BackendSrvRequest } from '@grafana/runtime';

type GrafanaPluginFetchRequest = Omit<BackendSrvRequest, 'url'>;

// The `incidentApi` function is a wrapper around the Grafana backend service request function.
// Initiate an incident request
export async function incidentApi<T extends JsonObject | Blob>(
  url: string,
  options: GrafanaPluginFetchRequest
): Promise<GrafanaPluginFetchResponse<T>> {
  const { grafanaPluginFetchClient } = await import('@grafana-irm/core/network');
  const { getPluginId } = await import('@grafana-irm/core/plugin');
  const finalUrl = `${getPluginId()}/${url}`;
  return grafanaPluginFetchClient.fetch<T>(finalUrl, options ?? {});
}

export async function incidentSocialImage(
  url: string,
  options: GrafanaPluginFetchRequest
): Promise<GrafanaPluginFetchResponse<Blob>> {
  return incidentApi<Blob>(url, { ...options, responseType: 'blob' });
}

// Initiate a grafana request to resources
export async function incidentResourceApi<T extends JsonObject>(
  url: string,
  options: GrafanaPluginFetchRequest
): Promise<GrafanaPluginFetchResponse<T>> {
  return incidentApi<T>(`resources/api/${url}`, options);
}

/// The `GrafanaInternalApiClient` class is responsible for interacting with the Grafana Incident API.
/// Importantly, it implements the `Client` interface and provides methods to send requests and handle responses.
/// The class handles setting up the necessary headers for requests and processes the responses to determine success or failure.
/// It also includes error reporting functionality.
export class GrafanaInternalApiClient implements Client {
  readonly #headers: Headers;

  public constructor() {
    this.#headers = new Headers();
    this.#headers.set('Accept', 'application/json');
    this.#headers.set('Content-Type', 'application/json');
  }

  public async fetch(path: string, payload: Json): Promise<ClientResponse> {
    const { isResponseSuccessful } = await import('@grafana-irm/core');
    const res = await incidentResourceApi(path, {
      method: 'POST',
      headers: this.#headers,
      data: payload != null ? JSON.stringify(payload) : undefined,
    });
    if (isResponseSuccessful(res)) {
      return { success: true, payload: res.data ?? {} };
    } else if (res?.status !== 502) {
      return {
        success: false,
        error: {
          status: res.status,
          statusText: res.statusText ?? '',
          message: res.data?.error as string,
        },
      };
    }
    return {
      success: false,
      error: {
        status: 502,
        statusText: 'Internal Server Error',
        message: `Failed to proxy to internal system`,
      },
    };
  }

  public reportError(error: unknown): void {
    console.error('Grafana API Client Error: ', error);
  }
}
