import { lazy } from 'react';

import { PluginExtensionComponentConfig } from '@grafana/data';
import { config } from '@grafana/runtime';

import { type SiftModalProps } from 'projects/Sift/SiftModal';
import { tryGte } from 'utils';
import { withSuspense } from 'utils/utils.lazy';

import { type SiftRunModalProps } from '../projects/Sift/RunSiftModal';
import {
  AlertInstancePluginLinkConfig,
  AppO11yOperationPluginLinkConfig,
  AppO11yServicePluginLinkConfig,
  CommandPalettePluginLinkConfig,
  ExplorePluginLinkConfig,
  ExtensionParams,
  IncidentRunSiftLinkConfig,
  K8sClusterPluginLinkConfig,
  K8sNamespacePluginLinkConfig,
  K8sPodPluginLinkConfig,
  K8sWorkloadPluginLinkConfig,
  NewPluginExtensionPoints,
  OnCallPluginLinkConfig,
  PanelPluginLinkConfig,
  PluginExtensionPoints,
  SiftPanelPluginLinkConfig,
} from './types';

const siftExtensionParams: ExtensionParams = {
  title: 'Run investigation',
  description: 'Investigate using Grafana Sift',
};

if (tryGte(config.buildInfo.version, '10.0.4')) {
  siftExtensionParams.category = 'Sift';
  siftExtensionParams.icon = 'search';
}

const LazyCreateInvestigationModal = lazy(() => import('./components/CreateInvestigation'));
const CreateInvestigationModal = withSuspense(LazyCreateInvestigationModal);

export const panelExtensionLinks: PanelPluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    targets: [PluginExtensionPoints.DashboardPanelMenu],
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: PluginExtensionPoints.DashboardPanelMenu } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const exploreExtensionLinks: ExplorePluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    targets: [PluginExtensionPoints.ExploreToolbarAction],
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: PluginExtensionPoints.ExploreToolbarAction } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const alertingExtensionLinks: AlertInstancePluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    targets: [NewPluginExtensionPoints.AlertInstanceAction],
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: NewPluginExtensionPoints.AlertInstanceAction } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const onCallAlertGroupExtensionLinks: OnCallPluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    targets: [NewPluginExtensionPoints.OnCallAlertGroupAction],
    onClick: (_, { context, openModal }): void => {
      const contextWrapper = { context, type: NewPluginExtensionPoints.OnCallAlertGroupAction } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

export const commandPaletteExtensionLinks: CommandPalettePluginLinkConfig[] = [
  {
    ...siftExtensionParams,
    targets: [NewPluginExtensionPoints.CommandPalette],
    onClick: (_, { openModal }): void => {
      const contextWrapper = { context: {}, type: NewPluginExtensionPoints.CommandPalette } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

const k8sClusterExtensionLink: K8sClusterPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.K8sClusterAction],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sClusterAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const k8sNamespaceExtensionLink: K8sNamespacePluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.K8sNamespaceAction],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sNamespaceAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const k8sWorkloadExtensionLink: K8sWorkloadPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.K8sWorkloadAction],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sWorkloadAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const k8sPodExtensionLink: K8sPodPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.K8sPodAction],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.K8sPodAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
export const k8sExtensionLinks = [
  k8sClusterExtensionLink,
  k8sNamespaceExtensionLink,
  k8sWorkloadExtensionLink,
  k8sPodExtensionLink,
];

export const siftPanelLink: SiftPanelPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.SiftPanelRunInvestigation],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.SiftPanelRunInvestigation } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};

const appO11yServiceExtensionLink: AppO11yServicePluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.AppO11yServiceAction],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.AppO11yServiceAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
const appO11yOperationExtensionLink: AppO11yOperationPluginLinkConfig = {
  ...siftExtensionParams,
  title: 'Run Sift investigation',
  targets: [NewPluginExtensionPoints.AppO11yOperationAction],
  onClick: (_, { context, openModal }): void => {
    const contextWrapper = { context, type: NewPluginExtensionPoints.AppO11yOperationAction } as const;
    openModal({
      title: 'New investigation',
      body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
    });
  },
};
export const appO11yExtensionLinks = [appO11yServiceExtensionLink, appO11yOperationExtensionLink];

export const incidentRunSiftLink: IncidentRunSiftLinkConfig[] = [
  {
    ...siftExtensionParams,
    targets: [NewPluginExtensionPoints.IncidentRunSiftModal],
    onClick: (_, { openModal }): void => {
      const contextWrapper = { context: {}, type: NewPluginExtensionPoints.IncidentRunSiftModal } as const;
      openModal({
        title: 'New investigation',
        body: ({ onDismiss }) => CreateInvestigationModal({ contextWrapper, onDismiss }),
      });
    },
  },
];

const LazySiftAnalysisModal = lazy(() => import('./components/SiftAnalysisModal'));
const SiftAnalysisModal = withSuspense(LazySiftAnalysisModal);

export const siftAnalysisModals: Array<
  {
    targets: string | string[];
  } & Omit<PluginExtensionComponentConfig<SiftModalProps>, 'type' | 'extensionPointId'>
> = [
  {
    title: 'Sift analysis modal',
    description: 'View details of a Sift analysis',
    targets: [NewPluginExtensionPoints.IncidentAnalysisModal],
    component: SiftAnalysisModal,
  },
  {
    title: 'Sift analysis modal',
    description: 'View details of a Sift analysis',
    targets: [NewPluginExtensionPoints.AssertsAnalysisModal],
    component: SiftAnalysisModal,
  },
  {
    title: 'Sift analysis modal',
    description: 'View details of a Sift analysis',
    targets: ['plugins/grafana-sift-panel/view-analysis'],
    component: SiftAnalysisModal,
  },
];

const LazyRunSiftModalWrapper = lazy(() => import('./components/RunSiftModalWrapper'));
const RunSiftModalWrapper = withSuspense(LazyRunSiftModalWrapper);

export const siftRunModal: {
  targets: string | string[];
} & Omit<PluginExtensionComponentConfig<SiftRunModalProps>, 'type' | 'extensionPointId'> = {
  title: 'Start an investigation',
  description: 'Start a Sift investigation',
  targets: [NewPluginExtensionPoints.IncidentRunSiftModal],
  component: RunSiftModalWrapper,
};
