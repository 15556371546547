import React, { useState } from 'react';

import { useStyles2 } from '@grafana/ui';

import { UseToken } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
import { EnableAppO11ySwitch } from 'components/EnableAppO11ySwitch';
import { OSSelectionWrapper } from 'components/OSSelectionWrapper';
import { RestartCollector } from 'features/agent-integrations/ConfigureIntegration/RestartCollector';
import { AlloyConfigModal } from 'features/agent-integrations/InstallCollector/AlloyConfigModal';
import { AlloyConfig } from 'pages/Source/ConfigurationDetails/Local/common/AlloyConfig';
import { useServiceInfo } from 'pages/Source/ConfigurationDetails/Local/common/useServiceInfo';
import { CollectorMode, OtelFlavor } from 'utils/enums';

import { SdkEnvironmentVariables } from './SdkEnvironmentVariables';
import { getStyles } from './styles';

const GenericInstructions = () => {
  const styles = useStyles2(getStyles);
  const {
    serviceName,
    serviceNamespace,
    serviceVersion,
    serviceInstanceId,
    form: serviceInfo,
    testButton,
  } = useServiceInfo({ includeServiceInstanceId: true });

  return (
    <>
      <li key="app-env-var-sdk">
        <h2>Sending data from applications</h2>
        <p>
          <b>NOTE:</b> We require that you run an Alloy instance per host and that applications send their data to the
          local Grafana Alloy.
        </p>
        <p>
          Configure the OpenTelemetry Auto Instrumentation and SDKs via environment variables to send OTLP data to the
          OpenTelemetry Collector. Support for environment variables varies across SDKs - check this{' '}
          <a
            href="https://github.com/open-telemetry/opentelemetry-specification/blob/main/spec-compliance-matrix.md#environment-variables"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>{' '}
          for details.
        </p>

        {serviceInfo}

        <SdkEnvironmentVariables
          serviceName={serviceName}
          serviceNamespace={serviceNamespace}
          serviceVersion={serviceVersion}
          serviceInstanceId={serviceInstanceId}
          flavor={OtelFlavor.Generic}
        />
      </li>
      <li key="app-env-var-collector">
        <h2>For OpenTelemetry Collector:</h2>
        <p>
          In your OpenTelemetry Collector, use the <code>otlp</code> exporter (gRPC) with the appropriate TLS options,
          including CA and cert files, and the host/port pointing to your Grafana Alloy installation, such as:{' '}
          <code>grafana-alloy-host:4317</code>.
        </p>
        <p>
          When it&apos;s not possible to use the TLS certificates, use the <code>insecure: true</code> option for your
          Collector.
        </p>
      </li>
      <li>
        <h2>Test your connection</h2>

        <p>
          In order to test that your connection is working, you need to first add a <code>service.name</code>.
        </p>

        {testButton}
      </li>
      <li key="ref">
        <h2>Reference</h2>
        <ul>
          <li>
            <a
              href="https://grafana.com/docs/opentelemetry/visualization/"
              target="_blank"
              rel="noreferrer"
              className={styles.bold}
            >
              Explore logs, metrics, and traces
            </a>
          </li>
          <li>
            <a
              href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/setup/collector/grafana-alloy/"
              target="_blank"
              rel="noreferrer"
              className={styles.bold}
            >
              Grafana Alloy
            </a>
          </li>
        </ul>
      </li>
    </>
  );
};

const CODE_JAVA_AGENT = (
  serviceName: string | undefined,
  serviceNamespace: string | undefined,
  serviceVersion: string | undefined
) => `export OTEL_EXPORTER_OTLP_ENDPOINT=http://localhost:4317
export OTEL_EXPORTER_OTLP_PROTOCOL=grpc
export OTEL_SERVICE_NAME=${serviceName ?? '<SERVICE_NAME>'}
export OTEL_RESOURCE_ATTRIBUTES=deployment.environment=<PRODUCTION_OR_STAGING>${serviceNamespace ? `,service.namespace=${serviceNamespace}` : ''}${serviceVersion ? `,service.version=${serviceVersion}` : ''}
java -javaagent:path/to/grafana-opentelemetry-java.jar -jar myapp.jar`;

const JavaInstructions = () => {
  const styles = useStyles2(getStyles);
  const { serviceName, serviceNamespace, serviceVersion, form: serviceInfo, testButton } = useServiceInfo();

  return (
    <>
      <li key="app-download">
        <h2>Download the Grafana Distribution of the OpenTelemetry JavaAgent</h2>
        <p>
          Click on the link{' '}
          <a
            href="https://github.com/grafana/grafana-opentelemetry-java/releases/latest/download/grafana-opentelemetry-java.jar"
            target="_blank"
            rel="noreferrer"
          >
            grafana-opentelemetry-java.jar
          </a>
          .
        </p>
      </li>
      <li key="app-env-run">
        <h2>Running the Java Application</h2>

        {serviceInfo}

        <Clipboard
          title="If Grafana Alloy is not running locally or doesn't use the default grpc endpoint, adjust OTEL_EXPORTER_OTLP_ENDPOINT or OTEL_EXPORTER_OTLP_PROTOCOL."
          multipleLines={true}
          expandHeight={true}
          code={CODE_JAVA_AGENT(serviceName, serviceNamespace, serviceVersion)}
          clipboardButtonType="below"
        />
      </li>

      <li>
        <h2>Test your connection</h2>

        <p>
          In order to test that your connection is working, you need to first add a <code>service.name</code>.
        </p>

        {testButton}
      </li>

      <li key="ref">
        <h2>Reference</h2>
        <ul>
          <li>
            <a
              href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/"
              target="_blank"
              rel="noreferrer"
              className={styles.bold}
            >
              Application Observability
            </a>
          </li>
          <li>
            <a
              href="https://grafana.com/docs/grafana-cloud/monitor-applications/application-observability/setup/collector/grafana-alloy/"
              target="_blank"
              rel="noreferrer"
              className={styles.bold}
            >
              Grafana Alloy
            </a>
          </li>
          <li>
            <a
              href="https://github.com/grafana/grafana-opentelemetry-java"
              target="_blank"
              rel="noreferrer"
              className={styles.bold}
            >
              Grafana OpenTelemetry distribution for Java
            </a>
          </li>
        </ul>
      </li>
    </>
  );
};

const Instructions = (flavor: OtelFlavor) => {
  const styles = useStyles2(getStyles);
  const [enableHostHours, setEnableHostHours] = useState(true);

  return (
    <div className={styles.instructions}>
      <ol>
        <li>
          <OSSelectionWrapper showStepBox={false} />
        </li>
        <li>
          <UseToken />
        </li>
        <li>
          <h4>Install and start Grafana Alloy</h4>
          <AlloyConfigModal showOSSelection={false} />
        </li>
        <li>
          <h4>Select optional features</h4>
          <EnableAppO11ySwitch enabled={enableHostHours} setEnabled={setEnableHostHours} />
        </li>
        <li>
          <AlloyConfig enableHostHours={enableHostHours} />
        </li>
        <li>
          <RestartCollector collectorMode={CollectorMode.Alloy} />
        </li>

        {flavor === OtelFlavor.Generic ? GenericInstructions() : JavaInstructions()}
      </ol>
    </div>
  );
};

export const GenericOpenTelemetryInstructions = () => Instructions(OtelFlavor.Generic);
export const JavaOpenTelemetryInstructions = () => Instructions(OtelFlavor.Java);
