import React, { Suspense, useState } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { ContentBox } from '../ContentBox';
import { AddEditExemption } from './AddEditExemption';
import { LockTable } from './LockTable';
import { RemoveAllExemptions } from './RemoveAllExemptions';
import { StreamSelectorTable } from './StreamSelectorTable';
import { useDownloadExemption, useExemptions } from '@/hooks/api-hooks';
import { noop } from '@/utils/methods';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    applyButtons: css({
      display: 'flex',
      gap: theme.spacing(1),
    }),
    contentContainer: css({
      background: theme.colors.background.primary,
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    }),
    controls: css({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
    }),
    grow: css({
      flexGrow: 1,
    }),
  };
};

const DownloadExemptionsComponent = () => {
  const downloadExemptions = useDownloadExemption();

  return (
    <Button aria-label={'Download exemptions'} variant="secondary" icon="file-download" onClick={downloadExemptions} />
  );
};

const DownloadExemptions = () => {
  return (
    <Suspense
      fallback={
        <Button
          aria-busy
          aria-label={'Download exemptions'}
          disabled
          variant="secondary"
          icon="file-download"
          onClick={noop}
        />
      }
    >
      <DownloadExemptionsComponent />
    </Suspense>
  );
};

export const Exemptions = () => {
  const styles = useStyles2(getStyles);
  const [exemptionType, setExemptionType] = useState('stream-selector');
  const { data: exemptions } = useExemptions();

  const radioButtonOptions = [
    {
      description: 'Specify logs that Adaptive Logs should not drop under any circumstances',
      label: 'Stream selector',
      text: 'Stream selector',
      value: 'stream-selector',
    },
    {
      description: 'A view of recommendations which have a "locked" drop rate specified by a user in the Patterns view',
      label: 'Lock',
      text: 'Lock',
      value: 'lock',
    },
  ];

  return (
    <ContentBox>
      <h3>Exemptions</h3>
      <div className={styles.contentContainer}>
        <div className={styles.controls}>
          <>
            <RadioButtonGroup
              options={radioButtonOptions}
              value={exemptionType}
              onChange={(v) => setExemptionType(v)}
            />

            <span className={styles.grow} />
          </>
          <span className={styles.applyButtons}>
            {exemptionType !== 'lock' && (
              <>
                <AddEditExemption />
                <RemoveAllExemptions />
                <DownloadExemptions />
              </>
            )}
          </span>
        </div>
        {exemptionType === 'lock' ? <LockTable /> : <StreamSelectorTable exemptions={exemptions.result} />}
      </div>
    </ContentBox>
  );
};
