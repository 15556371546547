import React, { type FC } from 'react';

import { QRCodeSVG } from 'qrcode.react';

import { Block } from '@grafana-irm/components';

interface Props {
  value: string;
  className?: string;
}

export const QRCode: FC<Props> = (props: Props) => {
  const { value, className = '' } = props;

  return (
    <Block bordered className={className}>
      <QRCodeSVG value={value} size={256} />
    </Block>
  );
};
