import { useMemo } from 'react';

import { PatternRecommendation } from '@/api/types';
import { getServiceNameFromSegment } from '@/utils/segments';

export function useSegments({ attribution, configured_drop_rate: patternDropRate }: PatternRecommendation) {
  const segments = useMemo(() => {
    return Object.entries(attribution).map(([segment, attribution]) => {
      // This implementation is very presumptive about what is in the segment matcher,
      // and will likely need to be revised when we allow more advanced segments.
      const serviceName = getServiceNameFromSegment(segment);

      const { configured_drop_rate: segmentDropRate, Volume: totalVolume } = attribution;
      const rate = segmentDropRate != null ? segmentDropRate : patternDropRate;

      return {
        droppedVolume: (totalVolume * rate) / 100,
        segment,
        serviceName,
        totalVolume,
        ...attribution,
      };
    });
  }, [attribution, patternDropRate]);

  return segments;
}
