import React, { useState } from 'react';

import { Select, useStyles2 } from '@grafana/ui';

import { OsBasedOptions, useCollectorSelector, UseToken } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
<h4>Select optional features</h4>;
import { EnableAppO11ySwitch } from 'components/EnableAppO11ySwitch';
import { OSSelectionWrapper } from 'components/OSSelectionWrapper';
import { RestartCollector } from 'features/agent-integrations/ConfigureIntegration/RestartCollector';
import { AlloyConfigModal } from 'features/agent-integrations/InstallCollector/AlloyConfigModal';
import { getStyles } from 'features/hosted-data-integrations/OpenTelemetry/styles';
import { CollectorMode } from 'utils/enums';

import { AlloyConfig } from '../common/AlloyConfig';
import { useServiceInfo } from '../common/useServiceInfo';

enum WindowsDotNetFramework {
  Core = '.NET/.NET Core',
  Framework = '.NET Framework',
}

enum LinuxEnvironment {
  Container = 'Container',
  Local = 'Local',
}

export const DotNetInstructions = () => {
  const styles = useStyles2(getStyles);
  const OSValue: OsBasedOptions = useCollectorSelector((state) => state.collector.selectedOs.osValue);

  const [winFramework, setWinFramework] = useState<WindowsDotNetFramework>(WindowsDotNetFramework.Core);
  const [linuxEnvironment, setLinuxEnvironment] = useState<LinuxEnvironment>(LinuxEnvironment.Container);
  const [enableHostHours, setEnableHostHours] = useState(true);

  const isLinux = [OsBasedOptions.Debian, OsBasedOptions.RedHat, OsBasedOptions.OtherLinuxDistros].includes(OSValue);

  const {
    serviceName,
    serviceNamespace,
    serviceVersion,
    serviceInstanceId,
    form: serviceInfo,
    testButton,
  } = useServiceInfo({ includeServiceInstanceId: true });

  return (
    <div className={styles.instructions}>
      <ol>
        <li>
          <OSSelectionWrapper showStepBox={false} />
        </li>
        <li>
          <UseToken />
        </li>
        <li>
          <h4>Install and start Grafana Alloy</h4>
          <AlloyConfigModal showOSSelection={false} />
        </li>
        <li>
          <h4>Select optional features</h4>
          <EnableAppO11ySwitch enabled={enableHostHours} setEnabled={setEnableHostHours} />
        </li>
        <li>
          <AlloyConfig enableHostHours={enableHostHours} />
        </li>
        <li>
          <RestartCollector collectorMode={CollectorMode.Alloy} />
        </li>

        {OSValue === OsBasedOptions.Windows && (
          <>
            <li className={styles.tabsWrapper}>
              <h2>Choose your framework</h2>
              <label htmlFor="dotnet-install-instructions-runtime-select">
                Select your framework
                <Select
                  className={styles.option}
                  options={[
                    { label: WindowsDotNetFramework.Core, value: WindowsDotNetFramework.Core },
                    { label: WindowsDotNetFramework.Framework, value: WindowsDotNetFramework.Framework },
                  ]}
                  value={winFramework}
                  inputId="dotnet-install-instructions-runtime-select"
                  allowCustomValue
                  onChange={(selected) => {
                    if (selected.value) {
                      setWinFramework(selected.value);
                    }
                  }}
                />
              </label>
            </li>
            <li>
              <h2>Install OpenTelemetry auto-instrumentation on Windows with IIS</h2>
              <p>
                Run the following commands in a PowerShell session (not cmd.exe command prompt) to download and globally
                install the OpenTelemetry auto-instrumentation to your system. Then set IIS environment variables to
                enable the instrumentation.
              </p>
              <p>This will cause IIS to restart.</p>
              <div className={styles.clipboardWithButtonBelow}>
                <Clipboard
                  code={CODE_WINDOWS_WITH_IIS}
                  multipleLines={true}
                  expandHeight={true}
                  clipboardButtonType="below"
                />
              </div>
            </li>
            {winFramework === WindowsDotNetFramework.Framework && (
              <li>
                <h2>Configure OTLP endpoint for .NET Framework</h2>
                <p>
                  To override the default OTLP endpoint that is used to export telemetry, and to customize resource
                  attributes, add OpenTelemetry values to the appSettings section of each application&apos;s web.config.
                  Write your service name and service namespace and we&apos;ll add it to the configuration:
                </p>

                {serviceInfo}

                <div className={styles.clipboardWithButtonBelow}>
                  <Clipboard
                    code={CODE_DOTNET_FRAMEWORK(serviceName, serviceNamespace, serviceVersion, serviceInstanceId)}
                    multipleLines={true}
                    expandHeight={true}
                    clipboardButtonType="below"
                  />
                </div>
              </li>
            )}
            {winFramework === WindowsDotNetFramework.Core && (
              <li>
                <h2>Configure OTLP endpoint for .NET / .NET Core</h2>
                <p>
                  To override the default OTLP endpoint that is used to export telemetry, and to customize resource
                  attributes, modify the application&apos;s web.config to add environment variables. Write your service
                  name and service namespace and we&apos;ll add it to the configuration:
                </p>

                {serviceInfo}

                <div className={styles.clipboardWithButtonBelow}>
                  <Clipboard
                    code={CODE_DOTNET_CORE(serviceName, serviceNamespace, serviceVersion, serviceInstanceId)}
                    multipleLines={true}
                    expandHeight={true}
                    clipboardButtonType="below"
                  />
                </div>
              </li>
            )}
          </>
        )}
        {isLinux && (
          <li className={styles.tabsWrapper}>
            <h2>Choose your environment</h2>
            <label htmlFor="dotnet-install-instructions-linux-environment-select">
              Select your environment
              <Select
                className={styles.option}
                options={[
                  { label: LinuxEnvironment.Container, value: LinuxEnvironment.Container },
                  { label: LinuxEnvironment.Local, value: LinuxEnvironment.Local },
                ]}
                value={linuxEnvironment}
                inputId="dotnet-install-instructions-linux-environment-select"
                allowCustomValue
                onChange={(selected) => {
                  if (selected.value) {
                    setLinuxEnvironment(selected.value);
                  }
                }}
              />
            </label>
          </li>
        )}
        {isLinux && linuxEnvironment === LinuxEnvironment.Container && (
          <li>
            <h2>Add auto instrumentation to a Linux container</h2>
            <p>
              Modify the application Dockerfile as follows to download and unpack .NET auto-instrumentation packages:
            </p>
            <div className={styles.clipboardWithButtonBelow}>
              <Clipboard
                code={CODE_LINUX_CONTAINER_DOCKERFILE}
                multipleLines={true}
                expandHeight={true}
                clipboardButtonType="below"
              />
            </div>
            <p>
              Activate auto-instrumentation by setting the OpenTelemetry home directory, and pre-pending your
              application invocation with the instrument.sh script:
            </p>
            <div className={styles.clipboardWithButtonBelow}>
              <Clipboard
                code={CODE_LINUX_CONTAINER_ENTRYPOINT}
                multipleLines={true}
                expandHeight={true}
                clipboardButtonType="below"
              />
            </div>
            <p>
              Environment variables can be used to override the default OTLP endpoint that is used to export telemetry,
              and to customize resource attributes. Write your service name and service namespace and we&apos;ll add it
              to the configuration:
            </p>

            {serviceInfo}

            <div className={styles.clipboardWithButtonBelow}>
              <Clipboard
                code={CODE_LINUX_CONTAINER_ENV_VARS(serviceName, serviceNamespace, serviceVersion, serviceInstanceId)}
                multipleLines={true}
                clipboardButtonType="below"
                expandHeight={true}
              />
            </div>
          </li>
        )}
        {((isLinux && linuxEnvironment === LinuxEnvironment.Local) || OSValue === OsBasedOptions.MacOs) && (
          <li>
            <h2>Run application with OpenTelemetry auto-instrumentation locally</h2>
            <p>Run the following in a terminal to download and unpack .NET auto-instrumentation packages:</p>
            <div className={styles.clipboardWithButtonBelow}>
              <Clipboard
                code={CODE_LOCAL_INSTALL_INSTRUMENTATION}
                multipleLines={true}
                expandHeight={true}
                clipboardButtonType="below"
              />
            </div>
            <p>
              Environment variables can be used to override the default OTLP endpoint that is used to export telemetry,
              and to customize resource attributes. Write your service name and service namespace and we&apos;ll add it
              to the configuration:
            </p>
            {serviceInfo}
            <div className={styles.clipboardWithButtonBelow}>
              <Clipboard
                code={CODE_LOCAL_ENV_VARS(serviceName, serviceNamespace, serviceVersion, serviceInstanceId)}
                multipleLines={true}
                expandHeight={true}
                clipboardButtonType="below"
              />
            </div>{' '}
          </li>
        )}

        <li>
          <h2>Test your connection</h2>

          <p>
            In order to test that your connection is working, you need to first add a <code>service.name</code>.
          </p>

          {testButton}
        </li>
      </ol>
    </div>
  );
};

const CODE_WINDOWS_WITH_IIS = `# Download the module
$module_url = "https://github.com/open-telemetry/opentelemetry-dotnet-instrumentation/releases/download/v1.4.0/OpenTelemetry.DotNet.Auto.psm1"
$download_path = Join-Path $env:temp "OpenTelemetry.DotNet.Auto.psm1"
Invoke-WebRequest -Uri $module_url -OutFile $download_path -UseBasicParsing

# Import the module to use its functions
Import-Module $download_path
# Download and install core files
Install-OpenTelemetryCore
# Set env vars to enable auto instrumentation in IIS and restart IIS
Register-OpenTelemetryForIIS
`;

const CODE_DOTNET_FRAMEWORK = (
  serviceName: string | undefined,
  serviceNamespace: string | undefined,
  serviceVersion: string | undefined,
  serviceInstanceId: string | undefined
) => `<configuration>
<appSettings>
    <add key="OTEL_EXPORTER_OTLP_ENDPOINT" value="http://localhost:4318" />
    <add key="OTEL_SERVICE_NAME" value="${serviceName ?? 'service-name'}" />
    <add key="OTEL_RESOURCE_ATTRIBUTES" value="${serviceNamespace ? `service.namespace=${serviceNamespace},` : ''}deployment.environment=production${serviceInstanceId ? `,service.instance.id=${serviceInstanceId}` : ''}${serviceVersion ? `,service.version=${serviceVersion}` : ''}" />
</appSettings>
</configuration>
`;

const CODE_DOTNET_CORE = (
  serviceName: string | undefined,
  serviceNamespace: string | undefined,
  serviceVersion: string | undefined,
  serviceInstanceId: string | undefined
) => `<configuration>
<system.webServer>
  <aspNetCore ...>
    <environmentVariables>
      <environmentVariable name="OTEL_EXPORTER_OTLP_ENDPOINT" value="http://localhost:4318" />
      <environmentVariable name="OTEL_SERVICE_NAME" value="${serviceName ?? 'service-name'}" />
      <environmentVariable name="OTEL_RESOURCE_ATTRIBUTES" value="${serviceNamespace ? `service.namespace=${serviceNamespace},` : ''}deployment.environment=production${serviceInstanceId ? `,service.instance.id=${serviceInstanceId}` : ''}${serviceVersion ? `,service.version=${serviceVersion}` : ''}" />
    </environmentVariables>
  </aspNetCore>
</system.webServer>
</configuration>
`;

const CODE_LINUX_CONTAINER_DOCKERFILE = `ARG OTEL_VERSION=1.4.0
ADD https://github.com/open-telemetry/opentelemetry-dotnet-instrumentation/releases/download/v\${OTEL_VERSION}/otel-dotnet-auto-install.sh otel-dotnet-auto-install.sh
RUN apt-get update && apt-get install -y curl unzip && \\
    OTEL_DOTNET_AUTO_HOME="/otel-dotnet-auto" sh otel-dotnet-auto-install.sh && \\
    chmod +x /otel-dotnet-auto/instrument.sh`;

const CODE_LINUX_CONTAINER_ENTRYPOINT = `ENV OTEL_DOTNET_AUTO_HOME="/otel-dotnet-auto"
ENTRYPOINT ["/otel-dotnet-auto/instrument.sh", "dotnet", "MyApp.dll"]`;

const CODE_LINUX_CONTAINER_ENV_VARS = (
  serviceName: string | undefined,
  serviceNamespace: string | undefined,
  serviceVersion: string | undefined,
  serviceInstanceId: string | undefined
) => `ENV OTEL_EXPORTER_OTLP_ENDPOINT=http://localhost:4318
ENV OTEL_SERVICE_NAME=${serviceName ?? 'service-name'}
ENV OTEL_RESOURCE_ATTRIBUTES=${serviceNamespace ? `service.namespace=${serviceNamespace},` : ''}deployment.environment=production${serviceInstanceId ? `,service.instance.id=${serviceInstanceId}` : ''}${serviceVersion ? `,service.version=${serviceVersion}` : ''}`;

const CODE_LOCAL_INSTALL_INSTRUMENTATION = `# Download the bash script
curl -sSfL https://github.com/open-telemetry/opentelemetry-dotnet-instrumentation/releases/download/v1.2.0/otel-dotnet-auto-install.sh -O

# Download core automatic instrumentation files
sh ./otel-dotnet-auto-install.sh

# Set executable permissions for the instrumentation script
chmod +x $HOME/.otel-dotnet-auto/instrument.sh

# Run your application with instrumentation
$HOME/.otel-dotnet-auto/instrument.sh dotnet YourNetApp.dll`;

const CODE_LOCAL_ENV_VARS = (
  serviceName: string | undefined,
  serviceNamespace: string | undefined,
  serviceVersion: string | undefined,
  serviceInstanceId: string | undefined
) =>
  `OTEL_EXPORTER_OTLP_ENDPOINT=http://localhost:4318 OTEL_SERVICE_NAME=${serviceName ?? 'service-name'} OTEL_RESOURCE_ATTRIBUTES=${serviceNamespace ? `service.namespace=${serviceNamespace},` : ''}deployment.environment=production${serviceInstanceId ? `,service.instance.id=${serviceInstanceId}` : ''}${serviceVersion ? `,service.version=${serviceVersion}` : ''} $HOME/.otel-dotnet-auto/instrument.sh dotnet YourNetApp.dll`;
