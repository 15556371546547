import { useCallback, useMemo, useRef, useState } from 'react';

import { anyToNumber } from '@grafana/data';

import { EditableSegment } from './types';
import { PatternUpdateModel } from '@/api/types';
import { ERROR_DROP_PERCENTAGE_RANGE } from '@/utils/constants';

export function useSegmentEditorState(initialSegments: EditableSegment[]) {
  // This is state that is used to record what has been edited.
  // It will never be rendered
  const updates = useRef<PatternUpdateModel['segments']>({});
  const [errorSegments, setErrorSegments] = useState(new Map<string, string>());
  const [modifiedSegments, setModifiedSegments] = useState(new Set<string>());

  const originalBySegment = useMemo(
    () => new Map(initialSegments.map((segment) => [segment.segment, segment])),
    [initialSegments]
  );

  const reset = useCallback(() => {
    Object.keys(updates.current!).forEach((key) => delete updates.current![key]);
    setErrorSegments(new Map());
    setModifiedSegments(new Set());
  }, []);

  const setSegmentDropRate = useCallback(
    (segment: EditableSegment, dropRate: string) => {
      const { current } = updates;
      const key = segment.segment;

      if (!current) {
        throw new Error("Why doesn't current exist?");
      }

      const value = Number(dropRate);

      if (value < 0 || value > 100) {
        errorSegments.set(key, ERROR_DROP_PERCENTAGE_RANGE);
        setErrorSegments(new Map(errorSegments));
        return;
      }
      if (errorSegments.delete(key)) {
        setErrorSegments(new Map(errorSegments));
      }

      const modification = current[key] || {};
      const original = originalBySegment.get(key);

      if (dropRate.trim() === '') {
        if (original?.dropRate != null && original?.dropRate !== '') {
          // Setting it to undefined will direct to deleting existing value
          modification.configured_drop_rate = undefined;
        } else {
          // Deleting it will cause no change
          delete modification.configured_drop_rate;
        }
      } else if (modification.configured_drop_rate === value) {
        // No change.
        return;
      } else {
        modification.configured_drop_rate = value;
      }

      // Update current updates
      if (Object.keys(modification).length) {
        current[key] = modification;

        // If we manually revert to the original, let's not consider it modified.
        if (current[key].configured_drop_rate === anyToNumber(originalBySegment.get(key)?.dropRate)) {
          delete current[key];
        }
      } else {
        delete current[key];
      }
      // Update the set of modified segments, indicated by the keys of the `updates.current` struct
      setModifiedSegments(new Set(Object.keys(current)));
    },
    [originalBySegment, errorSegments]
  );

  return { errorSegments, modifiedSegments, reset, setSegmentDropRate, updates };
}
