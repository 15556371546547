import React, { useMemo } from 'react';

import { Card, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';
import { Investigation } from 'grafana-ml-common/types';

import { InvestigationTagList } from './InvestigationTagList';

interface InvestigationProps {
  investigations: Investigation[];
}

export function Investigations({ investigations }: InvestigationProps) {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.investigations}>
      <ul>
        {investigations.map((i) => (
          <li key={i.id}>
            <InvestigationCard key={i.id} investigation={i} />
          </li>
        ))}
      </ul>
    </div>
  );
}

interface InvestigationCardProps {
  investigation: Investigation;
}

function InvestigationCard({ investigation }: InvestigationCardProps) {
  const styles = useStyles2(getStyles);
  const interestingComponent = useMemo(() => {
    const interestingCount = Object.values(investigation.analyses.countsByStage).reduce(
      (acc, state) => acc + state.interesting,
      0
    );
    return interestingCount > 0 ? (
      <span className={styles.interestingIcon}>
        <Tooltip content="Investigation contains interesting results">
          <Icon name="bolt" />
        </Tooltip>
      </span>
    ) : null;
  }, [investigation.analyses.countsByStage, styles.interestingIcon]);

  return (
    <Card href={`/a/grafana-ml-app/investigations/${investigation.id}`} className={styles.card}>
      <Card.Heading className={styles.heading}>
        {[interestingComponent]} {investigation.name}
      </Card.Heading>
      <Card.Meta className={styles.timestamp}>{[investigation.created]}</Card.Meta>
      <Card.Tags className={styles.tags}>
        <InvestigationTagList inputs={investigation.inputs ?? []} />
      </Card.Tags>
    </Card>
  );
}

const getStyles = () => {
  return {
    investigations: css`
      height: 100%;
      overflow-y: auto;
      container-type: size;
    `,
    interestingIcon: css`
      stroke: #ff8833;
      fill: #ff8833;
    `,
    card: css`
      @container (max-width: 525px) {
        display: flex;
        flex-direction: column;
        align-self: start;
        justify-content: flex-start;
      }
    `,
    icon: css`
      min-width: 40px;
    `,
    heading: css`
      display: flex;
      flex-direction: row;
    `,
    timestamp: css`
      min-width: 180px;
      margin-left: 20px;
    `,
    tags: css`
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 300px;
      @container (max-width: 525px) {
        display: flex;
        flex-direction: column;
        align-self: start;
        & ul {
          padding-top: 5px;
          padding-left: 22px;
          justify-content: flex-start;
        }
      }
    `,
  };
};
