import React, { FC, useEffect } from 'react';

import { useStyles2, IconButton } from '@grafana/ui';
import { Clipboard } from '@grafana-cloud/ui';

import { useGetInstanceConnectionsQuery, useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { InstallCard } from 'components/InstallCard';
import { ViewYourDashboardsSection } from 'features/agent-integrations/ViewYourDashboards/ViewYourDashboardsSection';
import { useApiToken } from 'hooks/useApiToken';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

import { CreateToken } from './components/CreateToken';
import { getInstructionStyles, openLitConfiguration } from './snippets';

interface LLMInstructionsProps {
  onBack: () => void;
}

export const LLMInstructions: FC<LLMInstructionsProps> = ({ onBack }) => {
  const { token } = useApiToken();
  const styles = useStyles2(getInstructionStyles);
  const successMessage = 'Your API key has been generated below!';
  const { trackRudderStackEvent } = useRudderStack();
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { data: instanceConnectionsDetails } = useGetInstanceConnectionsQuery();
  const integrationId = 'ai-observability';

  const GRAFANA_CLOUD_INSTANCE_ID = hostedDataDetails.id;
  const GRAFANA_CLOUD_OTLP_ENDPOINT = `${instanceConnectionsDetails?.otlpHttpUrl}/otlp`;

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.PageView, {
      integration_slug: integrationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <h1>
          <IconButton name="angle-left" size="xl" tooltip="Back to Home" onClick={onBack} />
          LLM Application Monitoring with OpenTelemetry
        </h1>
      </div>

      <ol className={styles.instructionList}>
        <li>
          <h2>Create Grafana Cloud Token</h2>
          <CreateToken
            className={styles.instructions}
            promId={hostedDataDetails.hmInstancePromId}
            successMessage={successMessage}
          />
        </li>
        <li>
          <h2>Install the OpenLIT Library</h2>
          <div className={styles.instructions}>
            <Clipboard
              multipleLines
              description="Copy and run the following command to install the OpenLIT library"
              code={`pip install openlit`}
            />
          </div>
        </li>

        <li>
          <h2>Add Environment Variables</h2>
          <div className={styles.instructions}>
            <Clipboard
              multipleLines
              description="Add the following environment variables to your application to configure the OTLP endpoint"
              code={openLitConfiguration(GRAFANA_CLOUD_OTLP_ENDPOINT, GRAFANA_CLOUD_INSTANCE_ID?.toString(), token)}
            />
          </div>
        </li>

        <li>
          <h2>Instrument your code</h2>
          <div className={styles.instructions}>
          <Clipboard
            multipleLines
            description="To begin collecting telemetry data, initialize the `openlit` library at the start of your application. This
          simple step hooks into your application to start monitoring its performance and behavior."
            code={`import openlit
openlit.init()`}
          />
          </div>
        </li>

        <li>
          <h2>Install Dashboards</h2>
          <div className={styles.instructions}>
            <InstallCard installCardStyles={styles.installCard} />
          </div>
        </li>

        <ViewYourDashboardsSection />
      </ol>
    </div>
  );
};
