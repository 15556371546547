import React from 'react';

import { css } from '@emotion/css';
import { round as lodashRound } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Divider, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { Bytes } from '@/components/Bytes';
import { useSegments } from '@/hooks/segment-hooks';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  columnValues: css({
    color: theme.colors.text.secondary,
  }),
  defaultValue: css({
    color: theme.colors.emphasize(theme.colors.text.secondary, 0.5),
    fontStyle: 'italic',
    userSelect: 'none',
  }),
  detailColumn: css({
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  }),
  expandSession: css({
    display: 'flex',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(15)}`,
    maxWidth: '80%',
  }),
  infoIcon: css({
    marginLeft: theme.spacing(1),
  }),
  serviceNameWrapper: css({
    marginLeft: theme.spacing(0),
  }),
});

export const ServiceNameBreakDown = ({ recommendation }: Props) => {
  const { configured_drop_rate: defaultConfiguredDropRate } = recommendation;
  const styles = useStyles2(getStyles);

  const segments = useSegments(recommendation);

  const hasDropRateOverrides = Object.keys(recommendation.segments || {}).length > 0;

  function getEffectiveDropRatio(segmentDropPercent: number | undefined) {
    if (segmentDropPercent === undefined) {
      return recommendation.configured_drop_rate / 100;
    }
    return segmentDropPercent / 100;
  }

  return (
    <div className={styles.serviceNameWrapper} data-testid="recommendation-break-down">
      <h3>Pattern breakdown by service</h3>
      <Divider />
      <div className={styles.expandSession}>
        <div className={styles.detailColumn}>
          <div>
            Service name
            <Tooltip placement={'top'} content={'Pattern generated from these identified services.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>
          {segments.map(({ serviceName: name }) => {
            return (
              <div key={name} className={styles.columnValues}>
                {name}
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Percentage
            <Tooltip placement={'top'} content={'Percentage of total volume per service.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>
          {segments.map(({ segment, Volume: volume }) => {
            const { volume: totalVolume } = recommendation;
            const percentage = (volume / totalVolume) * 100;
            const roundedPercentage = lodashRound(percentage, 2);

            return (
              <div key={segment} data-testid={`${segment}-percentage`} className={styles.columnValues}>
                {roundedPercentage === 0 && percentage > 0 ? '<1' : roundedPercentage}%
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Savings
            <Tooltip placement={'top'} content={'The projected savings over 15 days with the configured drop rage.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>

          {segments.map(({ configured_drop_rate: segmentDropRate, segment, Volume: volume }) => {
            const savings = volume * getEffectiveDropRatio(segmentDropRate);
            return (
              <div className={styles.columnValues} key={segment} data-testid={`${segment}-savings`}>
                <Bytes bytes={savings} decimals={1} />
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Volume
            <Tooltip placement={'top'} content={'The ingested volume over the last 15 days.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>

          {segments.map(({ segment, Volume: volume }) => {
            return (
              <div className={styles.columnValues} key={segment} data-testid={`${segment}-volume`}>
                <Bytes bytes={volume} decimals={1} />
              </div>
            );
          })}
        </div>
        {hasDropRateOverrides && (
          <div className={styles.detailColumn}>
            <div>
              Drop rate
              <Tooltip
                placement={'top'}
                content={'The per-service drop rate, which will override the default pattern drop rate if it is set.'}
              >
                <Icon className={styles.infoIcon} name="info-circle" />
              </Tooltip>
            </div>

            {segments.map(({ configured_drop_rate, segment }) => {
              const displayDropRate =
                configured_drop_rate == null ? (
                  <Tooltip
                    placement={'left'}
                    content={`The default drop rate for this pattern is ${defaultConfiguredDropRate}%.`}
                  >
                    <span className={styles.defaultValue}>{defaultConfiguredDropRate}%</span>
                  </Tooltip>
                ) : (
                  `${configured_drop_rate}%`
                );
              return (
                <div className={styles.columnValues} key={segment} data-testid={`${segment}-drop-rate`}>
                  {displayDropRate}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
