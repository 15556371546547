import { usePluginContext } from '@grafana/data';

import { AppPluginSettings } from '@/pages/AppConfig';

export function usePluginJsonData() {
  const plugin = usePluginContext();

  const jsonData: AppPluginSettings = {
    ...plugin?.meta.jsonData,
  };

  return jsonData;
}
