import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { inRange as lodashInRange } from 'lodash';

import { FeatureState, GrafanaTheme2 } from '@grafana/data';
import { FeatureBadge, FieldValidationMessage, Input, useStyles2 } from '@grafana/ui';

import { DROP_RATE_UPPER_LIMIT_EXCLUSIVE } from '@/utils/constants';

type EditableSegment = { dropRate: string; segment: string; serviceName: string };

type Props<S extends EditableSegment> = {
  disabled?: boolean;
  errorSegments: Map<string, string>;
  getPlaceholderText: (segment: S) => string | undefined;
  modifiedSegments: Set<string>;
  rightColumnHeader: string;
  rightColumnRenderCell: (segment: S) => React.ReactNode;
  segments: S[];
  segmentSuffixRender?: (segment: S) => React.ReactNode;
  setSegmentDropRate: (segment: S, dropRate: string) => void;
};

export function SegmentEditor<S extends EditableSegment>({
  disabled,
  errorSegments,
  getPlaceholderText,
  modifiedSegments,
  rightColumnHeader,
  rightColumnRenderCell,
  segments,
  segmentSuffixRender,
  setSegmentDropRate,
}: Props<S>) {
  const styles = useStyles2(getStyles);

  const badge = useMemo(
    () => (
      <FeatureBadge
        featureState={FeatureState.preview}
        tooltip="The ability to set drop rates per-service is in public preview."
      />
    ),
    []
  );

  return (
    <div className={cx(styles.editor, styles.scrollLongTable)}>
      <div className={styles.header}>
        <div>Per-service drop rate overrides {badge}</div>
        <div>{rightColumnHeader}</div>
      </div>
      <div>
        {segments.map((segment) => (
          <div key={segment.segment} className={styles.row}>
            <div>
              {modifiedSegments.has(segment.segment) && <span className={styles.dot} />}
              <Input
                aria-label={`Drop rate for ${segment.segment}`}
                width={8}
                type="number"
                placeholder={getPlaceholderText(segment)}
                defaultValue={segment.dropRate}
                disabled={disabled}
                suffix="%"
                invalid={errorSegments.has(segment.segment)}
                onFocus={(event) => event.target.select()}
                onChange={(v) => {
                  if (lodashInRange(Number(segment.dropRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)) {
                    setSegmentDropRate(segment, v.currentTarget.value);
                  }
                }}
              />
              {errorSegments.has(segment.segment) && (
                <FieldValidationMessage className={styles.rowError}>
                  {errorSegments.get(segment.segment)}
                </FieldValidationMessage>
              )}
            </div>
            <div className={styles.cell}>
              {segment.serviceName}
              {segmentSuffixRender && segmentSuffixRender(segment)}
            </div>
            <div className={styles.rightColumnCell}>{rightColumnRenderCell(segment)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    cell: css({
      ...theme.typography.body,
    }),
    dot: css({
      backgroundColor: theme.colors.primary.main,
      borderRadius: theme.shape.radius.circle,
      display: 'inline-block',
      height: '6px',
      left: 0,
      marginTop: theme.spacing(1.5),
      position: 'absolute',
      width: '6px',
    }),
    editor: css({
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    }),
    header: css({
      display: 'grid',
      gridTemplateColumns: `1fr auto`,
      marginBottom: theme.spacing(1),
      ...theme.typography.bodySmall,
      alignItems: 'end',
      background: theme.colors.background.primary,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      position: 'sticky',
      top: 0,
      // Want it to float above the Field component,
      zIndex: theme.zIndex.tooltip,
    }),
    modifiedSegment: css({
      borderBottom: 'cyan 1px dotted',
      fontStyle: 'italic',
    }),
    rightColumnCell: css({
      ...theme.typography.bodySmall,
      color: theme.colors.emphasize(theme.colors.secondary.text, 0.4),
    }),

    row: css({
      '&:last-child': {
        borderBottom: `1px solid ${theme.colors.border.weak}`,
      },
      alignItems: 'center',
      borderTop: `1px solid ${theme.colors.border.weak}`,
      display: 'grid',
      gridColumnGap: theme.spacing(1),
      gridTemplateColumns: `auto 1fr auto`,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    }),
    rowError: css({
      position: 'absolute',
      zIndex: theme.zIndex.tooltip,
    }),
    scrollLongTable: css({
      maxHeight: '30vh',
      overflowY: 'auto',
      position: 'relative',
    }),
  };
}
