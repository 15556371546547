import { lazy } from 'react';

import { withSuspense } from 'utils/utils.lazy';

import { ExtensionComponentConfig } from './types';

const LazyAlertingHomepageCallout = lazy(() => import('./components/Alerting/AlertingHomepageCallout'));
const AlertingHomepageCallout = withSuspense(LazyAlertingHomepageCallout);

export const alertingHomePageCallout: ExtensionComponentConfig = {
  targets: ['grafana/alerting/home'],
  title: 'Machine Learning',
  description: 'Machine Learning',
  component: AlertingHomepageCallout,
};
