import { isEmpty as lodashIsEmpty } from 'lodash';

import { Metrics, PatternRecommendation } from '@/api/types';

export function getSummaryVolumes(recommendations: PatternRecommendation[]) {
  /** The total  */
  let total = 0;

  /** Total dropped volume */
  let totalDropped = 0;

  /** Calculates the sum of volume dropped if recommended drop rates were applied */
  let totalDroppedIfRecommendationsApplied = 0;

  recommendations.forEach((r) => {
    // Add the total volume
    total += r.volume;

    // Add the total volume if we applied recommended drop rates (excluding locked patterns, and effects of segment overrids)
    {
      const { locked } = r; // Worrying about locked will only occur with the "apply recommended" with no specific selection
      const patternRate = locked ? r.configured_drop_rate : r.recommended_drop_rate;

      for (const segment of Object.values(r.attribution)) {
        const segmentRate = segment.configured_drop_rate !== undefined ? segment.configured_drop_rate : patternRate;
        totalDroppedIfRecommendationsApplied += (segment.Volume * segmentRate) / 100;
      }
    }

    // current dropped volumes
    for (const segment of Object.values(r.attribution)) {
      const rate = segment.configured_drop_rate !== undefined ? segment.configured_drop_rate : r.configured_drop_rate;
      const volume = segment.Volume;
      const dropped = (volume * rate) / 100;

      totalDropped += dropped;
    }
  });

  return { total, totalDropped, totalDroppedIfRecommendationsApplied };
}

// Disabling the use of this until https://github.com/grafana/grafana-adaptivelogs-app/issues/418 is resolved
export const getAbsoluteTotalVolume = (metrics?: Metrics) => {
  if (lodashIsEmpty(metrics) || (metrics.ingested_volume === 0 && metrics.dropped_volume === 0)) {
    return null;
  } else {
    return metrics.ingested_volume + metrics.dropped_volume;
  }
};
