import React, { useState } from 'react';

import { Field, Select, Tooltip } from '@grafana/ui';

import { queryIngestFilterOptions } from './ranges';
import { QueryIngest, QueryIngestRange } from './types';

export function QueryIngestDescription({ range }: QueryIngest) {
  const { description, label } = range;

  const tooltip = `In the last 15 days ${description} of the ingested log lines matching this pattern were queried.`;

  return (
    <Tooltip content={tooltip}>
      <span>{label}</span>
    </Tooltip>
  );
}

type Props = {
  className?: string;
  initialValue?: QueryIngestRange;
  setFilter: (filter: QueryIngestRange | undefined) => void;
};

export function QueryFrequencyFilter({ className, initialValue, setFilter }: Props) {
  const [initialSelection] = useState(queryIngestFilterOptions.find((o) => o.value === initialValue));

  return (
    <Field label="Query&nbsp;frequency" className={className}>
      <Select<QueryIngestRange>
        maxMenuHeight={1000}
        isClearable={true}
        data-testid="query-ingest-filter"
        defaultValue={initialSelection}
        options={queryIngestFilterOptions}
        placeholder="All"
        onChange={(v) => {
          setFilter(v?.value);
        }}
      />
    </Field>
  );
}
