import { config } from '@grafana/runtime';

export function getGrafanaVersion(): {
  major?: number;
  minor?: number;
  patch?: number;
} {
  const regex = /^([1-9]?[0-9]*)\.([1-9]?[0-9]*)\.([1-9]?[0-9]*)/;
  const match = regex.exec(config.buildInfo.version);

  if (match != null) {
    return {
      major: Number(match[1]),
      minor: Number(match[2]),
      patch: Number(match[3]),
    };
  }

  return {};
}

export const isCurrentGrafanaVersionEqualOrGreaterThan = ({
  minMajor,
  minMinor = 0,
  minPatch = 0,
}: {
  minMajor: number;
  minMinor?: number;
  minPatch?: number;
}): boolean => {
  const { major, minor, patch } = getGrafanaVersion();
  if (major === undefined || minor === undefined || patch === undefined) {
    return false;
  }
  return (
    major > minMajor ||
    (major === minMajor && minor > minMinor) ||
    (major === minMajor && minor === minMinor && patch >= minPatch)
  );
};
