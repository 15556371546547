import { CLOUD_PLATFORM } from 'feature/common/types/CloudPlatform.enum';
import { TestGroup } from '../types/TestGroup';

export const CLOUD_PLATFORM_TITLES: { [key: string]: string } = {
  [CLOUD_PLATFORM.AWS]: 'Amazon Web Services',
  [CLOUD_PLATFORM.GCP]: 'Google Cloud Platform',
  [CLOUD_PLATFORM.AZURE]: 'Azure',
};

export const DEFAULT_ONBOARDING_GROUP = TestGroup.CONTROL;

export const START_HEADER = 'Get started in a few easy steps';
export const CREATE_DASHBOARD_HEADER = 'Create your first dashboard';
export const TELL_US_WHAT_TO_CONNECT = 'Tell us what data you want to connect to Grafana Cloud.';

export const MOBILE = '540px';
export const TABLET = '820px';
export const DESKTOP = '1095px';
export const ULTRAWIDE = '1495px';

export const getSelectAndCreateDashboardTitle = (name: string) => {
  return `There are a couple of ways to connect your ${name} data. Choose one to get started.`;
};

export enum OnboardingPaths {
  cloudServices = 'cloud-services',
  scaleCentralize = 'scale-and-centralize',
  createDashboard = 'select-and-create-dashboard',
  logsOnboarding = 'logs-onboarding',
  prometheus = 'prometheus',
  hostedData = 'hosted-data',
  customAwsOptions = 'custom-aws-options',
  customAwsData = 'custom-aws-data',
  customGcpOptions = 'custom-gcp-options',
  customGcpData = 'custom-gcp-data',
  customAzureOptions = 'custom-azure-options',
  otelOnboarding = 'otel',
  infrastructureOnboarding = 'infrastructure',
  customAzureData = 'custom-azure-data',
}
