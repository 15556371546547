import { PatternRecommendation, RecommendationResponseItem } from './types';
import { getQueryIngest } from '@/components/QueryIngestRatio/types';

function createSegmentsMap(item: RecommendationResponseItem) {
  const segments: PatternRecommendation['segments'] = {};
  Object.entries(item.attribution).forEach(([key, { configured_drop_rate }]) => {
    if (configured_drop_rate !== undefined) {
      segments[key] = { configured_drop_rate };
    }
  });
  return segments;
}

function createQueryIngest(item: RecommendationResponseItem) {
  return getQueryIngest(item.queried_lines, item.ingested_lines);
}

export function transformRecommendation(item: RecommendationResponseItem): PatternRecommendation {
  const { ingested_lines, queried_lines, ...keep } = item;

  return {
    ...keep,
    queryIngest: createQueryIngest(item),
    segments: createSegmentsMap(item),
  };
}
