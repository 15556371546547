import { lazy, Suspense } from 'react';

import { AppPlugin, PluginExtensionPoints } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import type { AppRootProps, OnCallPluginConfigPageProps, OnCallPluginMetaJSONData } from 'app-types';
import { addExtensionComponent, getIsIrmPluginPresent } from '@grafana-irm/core/plugin';
import { isCurrentGrafanaVersionEqualOrGreaterThan } from '@grafana-irm/core/grafanaVersion';

const MobileAppConnection = lazy(() => import('containers/MobileAppConnection/MobileAppConnection'));
const GrafanaPluginRootPage = lazy(() => import('plugin/GrafanaPluginRootPage'));
const LazyPluginConfigPage = lazy(() => import('containers/PluginConfigPage/PluginConfigPage'));

function LazyMobileAppConnection() {
  return (
    <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
      <MobileAppConnection />
    </Suspense>
  );
}

function RootPage(props: AppRootProps) {
  return (
    <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
      <GrafanaPluginRootPage {...props} />
    </Suspense>
  );
}

function ConfigPage(props: OnCallPluginConfigPageProps) {
  return (
    <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
      <LazyPluginConfigPage {...props} />
    </Suspense>
  );
}

const plugin = new AppPlugin<OnCallPluginMetaJSONData>().setRootPage(RootPage).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: ConfigPage,
  id: 'configuration',
});

if (isUseProfileExtensionPointEnabled()) {
  addExtensionComponent<OnCallPluginMetaJSONData>(plugin, {
    title: 'IRM',
    description: 'IRM settings',
    component: LazyMobileAppConnection,
    targets: ['grafana/user/profile/tab'],
  });
}

function isUseProfileExtensionPointEnabled(): boolean {
  return (
    isCurrentGrafanaVersionEqualOrGreaterThan({ minMajor: 10, minMinor: 3 }) &&
    'configureExtensionComponent' in plugin &&
    PluginExtensionPoints != null &&
    'UserProfileTab' in PluginExtensionPoints &&
    !getIsIrmPluginPresent()
  );
}

export { plugin };
