import React, { lazy } from 'react';

import { getMutableTimeRange } from 'utils/timeRange';
import { withSuspense } from 'utils/utils.lazy';
import { getTimeZoneWithDefault } from 'utils/utils.timeZone';

import { configureExploreOrPanelLink } from './configureLink';
import { assertExploreOrPanelContext } from './guards';
import { ExtensionLinkConfig, ExtensionLinkParams, ExtensionPointIds } from './types';
import { createLinkConfig } from './utils';

const LazyForecastModal = lazy(() => import('./components/ForecastModal/ForecastModal'));
const LazyOutlierModal = lazy(() => import('./components/OutlierModal/OutlierModal'));
const ForecastModal = withSuspense(LazyForecastModal);
const OutlierModal = withSuspense(LazyOutlierModal);

export const extensionLinks: ExtensionLinkConfig[] = [
  createForecastLinkConfig({
    targets: [ExtensionPointIds.DashboardPanelMenu, ExtensionPointIds.ExploreToolbarAction],
    configure: configureExploreOrPanelLink,
    onClick: (_, helpers) => {
      const { context, openModal } = helpers;
      assertExploreOrPanelContext(context);
      const [query] = context.targets;

      openModal({
        title: 'Forecast preview',
        body: (props) => <ForecastModal {...props} query={query} timeZone={getTimeZoneWithDefault(context.timeZone)} />,
        height: '80%',
        width: '80%',
      });
    },
  }),
  createOutlierLinkConfig({
    targets: [ExtensionPointIds.DashboardPanelMenu, ExtensionPointIds.ExploreToolbarAction],
    configure: configureExploreOrPanelLink,
    onClick: (_, helpers) => {
      const { context, openModal } = helpers;
      assertExploreOrPanelContext(context);
      const [query] = context.targets;

      openModal({
        title: `Outlier detection preview`,
        body: (props) => (
          <OutlierModal
            {...props}
            timeRange={getMutableTimeRange(context.timeRange)}
            query={query}
            timeZone={getTimeZoneWithDefault(context.timeZone)}
          />
        ),
        height: '80%',
        width: '80%',
      });
    },
  }),
];

function createForecastLinkConfig(params: ExtensionLinkParams): ExtensionLinkConfig {
  // Applying default values for a forecast link
  const title = params.title ?? 'Create forecast';
  const description = params.description ?? 'Use Machine Learning to forecast this query into the future';
  const category = params.category ?? 'Machine Learning';
  const icon = params.icon ?? 'gf-ml';

  return createLinkConfig({ ...params, title, description, category, icon });
}

function createOutlierLinkConfig(params: ExtensionLinkParams): ExtensionLinkConfig {
  // Applying default values for a outlier link
  const title = params.title ?? 'Detect outliers';
  const description = params.description ?? 'Use Machine Learning to detect outlying series in this query';
  const category = params.category ?? 'Machine Learning';
  const icon = params.icon ?? 'gf-ml';

  return createLinkConfig({ ...params, title, description, category, icon });
}
