import { GenerateTokenE2ESelectors } from '@grafana-cloud/access-policies';
import { CollectorOSSelectionSelectors } from '@grafana-cloud/collector';

import { Components } from './components';

export const Pages = {
  Catalog: {
    url: '/connections/add-new-connection',
    search: Components.Search,
    categoryHeader: (category: string) => `category-header ${category}`,
    cardTitle: (title: string) => `card-title ${title}`,
    alphaIntegrationModalButton: 'take-survey-button',
  },
  Source: {
    url: (id: string, page?: string) => `/connections/add-new-connection/${id}${page ? '?page=' + page : ''}`,
    tabs: (id: string) => `tab ${id}`,
    uninstallButton: 'uninstall-button',
    removeIntegration: 'remove-integration-button',
    Overview: {
      title: 'overview-title',
    },
    AgentConfig: {
      agentConfigButton: 'agent-config-button',
      exampleConfigButton: 'example-config-button',
      preInstructions: 'pre-instructions',
      hostnameRelabelInput: 'hostname-relabel-input',
      integrationInstructionsHeader: 'integration-instructions-header',
      logsInstructionsHeader: 'logs-instructions-header',
      metricsInstructionsHeader: 'metrics-instructions-header',
      postInstructions: 'post-instructions',
      restartCommand: 'restart-command',
      testConnectionButton: 'test-connection-button',
      alloyAdvancedIntegrationsBlock: 'alloy-advanced-integrations-block',
      alloyAdvancedIntegrationsInstructions: 'alloy-advanced-integrations-instructions',
      alloyAdvancedLogsBlock: 'alloy-advanced-logs-block',
      alloyAdvancedLogsInstructions: 'alloy-advanced-logs-instructions',
      alloyAdvancedMetricsBlock: 'alloy-advanced-metrics-block',
      alloyAdvancedMetricsInstructions: 'alloy-advanced-metrics-instructions',
      alloySimpleBlock: 'alloy-simple-block',
      alloyGeneralGuidance: 'alloy-general-guidance',
      makeOptionalSelectionsCollapse: 'make-optional-selections-collapse',
    },
    Config: {
      osSelector: CollectorOSSelectionSelectors.osSelection,
      install: 'install-button',
      viewDashboardsBottomButton: 'view-dashboards-button',
    },
    GenerateAPI: {
      apiTokenInput: GenerateTokenE2ESelectors.tokenNameInput,
      apiTokenSubmitButton: GenerateTokenE2ESelectors.submitButton,
      apiTokenSuccessMessage: GenerateTokenE2ESelectors.successMessage,
    },
    AgentModeSelection: {
      agentModeSelectionPage: 'agent-mode-selection-page',
      agentConfigConvertPage: 'agent-config-convert-page',
      agentModeInfo: 'agent-mode-info',
    },
  },
  SaaSIntegration: {
    addScrapeJobButton: `add-scrape-job-button`,
    createScrapeJobButton: `create-scrape-job-button`,
    saveScrapeJobButton: `save-scrape-job-button`,
    cancelScrapeJobButton: `cancel-scrape-job-button`,
    testConnectionButton: `test-connection-button`,
    regionsSelect: `regions-select`,
    serviceSelect: `service-select`,
    serviceMultiSelect: `service-multi-select`,
    serviceSelectList: `service-select-list`,
    serviceSelectEmpty: `service-select-empty`,
    serviceSearchInput: 'service-search-input',
    customNamespacesInput: 'custom-namespaces-input',
    configureServicesEmpty: `configure-services-empty`,
    configureServicesMetrics: `configure-services-metrics`,
    selectAllMetricsCheckbox: `select-all-metrics-checkbox`,
    serviceHeader: 'service-header',
    serviceHeaderMetricsSelected: 'service-header-metrics-selected',
    serviceIcon: `service-icon`,
    scrapeJobsTable: `scrape-jobs-table`,
    editScrapeJobButton: (name: string) => `edit-scrape-job-button ${name}`,
    deleteScrapeJobButton: (name: string) => `delete-scrape-job-button ${name}`,
    serviceDetails: (service: string) => `service-details ${service}`,
    alertParagraph: (type: string) => `alert ${type}`,
    scrapeJobCard: (name: string) => `job-card ${name}`,
    scrapeJobServicesTable: `scrape-job-services-table`,
    scrapeJobTableRow: (name: string) => `scrape-job-table-row ${name}`,
    scrapeJobRowCheckbox: (name: string) => `scrape-job-row-checkbox ${name}`,
    scrapeJobTableActionsButton: (name: string) => `scrape-job-table-actions-button ${name}`,
    editServiceButton: (name: string) => `edit-scrape-job-service-button ${name}`,
    deleteServiceButton: (name: string) => `delete-scrape-job-service-button ${name}`,
    serviceMetricsTable: (name: string) => `service-metrics-table ${name}`,
    modalFooter: `modal-footer`,
    viewEc2Button: 'view-ec2-button',
    scrapeJobsEmpty: `scrape-jobs-empty`,
    selectAllScrapeJobs: `select-all-scrape-jobs`,
    backToJobList: `back-to-job-list`,
    scrapeJobRowLink: (name: string) => `scrape-job-row-link ${name}`,
    searchByServiceOrMetricInput: 'search-by-service-or-metrics-input',
    statisticsSelectField: 'statistics-select-field',
    statisticsMultiSelector: `service-metrics-statistics-multi-selector`,
    statisticsApplyButton: 'statistics-apply-button',
    addNewServicesButton: 'scrape-jobs-add-new-services',
    selectAllJobServices: 'select-all-services-checkbox',
    serviceRowCheckbox: (name: string) => `service-row-checkbox ${name}`,
    deleteSelectedServices: 'delete-selected-services',
  },
  MetricsEndpointIntegration: {
    testConnectionButton: `test-connection-button`,
    saveScrapeJobButton: `save-scrape-job-button`,
    authParam: (name: string) => `auth-param-${name}`,
  },
};
